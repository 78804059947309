<template>
  <v-container fluid>
    <v-dialog
      max-width="90%"
      transition="dialog-bottom-transition"
      @click:outside="outsideDialog()"
      v-model="formDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- add new member button in /Mis mienbros -->
        <v-btn
          v-if="route.includes('/miembros') && create"
          v-bind="attrs"
          v-on="on"
          @click="editContentBranches()"
          color="accent"
          :small="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
          :fab="$vuetify.breakpoint.smAndDown"
          :disabled="
            !user.permissions.Members || !user.permissions.Members.create
          "
        >
          <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
          <span v-else class="font-weight-bold black--text"
            >+ Nuevo miembro</span
          >
        </v-btn>

        <!-- edit member information in /Mis miembros -->
        <v-btn
          v-else-if="route.includes('/miembros') && update"
          fab
          small
          elevation="1"
          v-on="on"
          v-bind="attrs"
          @click="getform()"
          :disabled="
            !user.permissions.Members || !user.permissions.Members.update
          "
        >
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>

        <!-- user avatar in Member accounts -->
        <v-avatar
          v-else
          color="wDarkerBlue"
          :size="$vuetify.breakpoint.lgAndUp ? 80 : 50"
          style="cursor: pointer; color: white"
          v-bind="attrs"
          v-on="on"
          class="step-1"
          @click="getform(), shepherdTour.next()"
        >
          <v-icon v-if="image == 'NOT_ASSIGNED'" color="white"
            >mdi-account</v-icon
          >
          <v-img v-else :src="image"></v-img>
        </v-avatar>
      </template>

      <template v-slot:default="dialog">
        <v-card color="white" class="pa-4" :disabled="loadingB">
          <!-- User profile image -->
          <div class="d-flex justify-center justify-space-between pt-4">
            <v-row>
              <v-col cols="6" class="pt-0">
                <v-btn
                  elevation="0"
                  text
                  small
                  fab
                  @click="outsideDialog(), (dialog.value = false)"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex align-center justify-end pt-0">
                <v-btn
                  style="z-index: 5; position: fixed"
                  :loading="loadingB"
                  width="150"
                  color="#fdb504"
                  @click="updateMemberInfomation()"
                  class="rounded-pill font-weight-bold"
                >
                  <v-icon class="pr-2">mdi-content-save</v-icon> Guardar
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <image-uploader
                  :imgUrl="profilePicture"
                  @save="handleImage"
                  class="mb-5"
                  v-if="image != 'NOT_ASSIGNED'"
                />
                <image-uploader
                  imgUrl="https://w7.pngwing.com/pngs/211/382/png-transparent-silhouette-avatar-avatar-silhouettes-animals-public-relations-monochrome.png"
                  @save="handleImage"
                  class="mb-5"
                  v-else
                />
              </v-col>
            </v-row>
          </div>
          <!-- Form user title -->
          <v-card-text
            :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-lext'"
            class="section-title pa-5 pb-1"
          >
            Datos de
            <span v-if="route.includes('/miembros') && create">nuevo</span>
            miembro
          </v-card-text>
          <!-- Form user content -->
          <v-card class="mx-5 pa-0" elevation="1">
            <v-form ref="formuser">
              <v-row class="ma-0">
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                  v-if="(create || update) && user.role != 'BRANCH' && user.hierarchyType == 'TWO_LEVELS'"
                >
                  <!-- works only on national account view -->
                  <v-combobox
                    filled
                    dense
                    :error-messages="errorCapitulo"
                    :value="capitulos.contField.company"
                    :disabled="create ? false : true"
                    @change="(v) => editBranch(v)"
                    rounded
                    :append-icon="create ? '$dropdown' : ''"
                    :items="setBranches"
                    :label="capitulos.label"
                    class="ma-0 pa-0"
                  />
                  <!--:value="capitulos.contField.company"
                :label="capitulos.label" -->
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                  v-if="(create || update) && user.role != 'BRANCH'"
                >
                  <v-text-field
                    filled
                    dense
                    :disabled="create"
                    readonly
                    rounded
                    label="ID Único de miembro"
                    :value="idUser"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                  v-for="(item, i) in orgData"
                  :key="i"
                >
                  <!-- birth date selector  -->
                  <date-selector
                    v-if="
                      item.label == 'Fecha de Nacimiento' ||
                      item.label == 'Miembro desde'
                    "
                    @change="handleDate"
                    :picker-date-label="item.label"
                    :pickedDate="getDateFormat(item.contField)"
                  />
                  <!--  -->
                  <v-combobox
                    v-else-if="
                      item.label == 'Género' ||
                      item.label == 'Grado máximo de estudios'
                    "
                    :items="item.label == 'Género' ? items_gener : items_school"
                    rounded
                    clearable
                    filled
                    :error-messages="item.error"
                    :placeholder="item.label"
                    class="pb-0"
                    :label="item.label"
                    v-model="item.contField"
                  />
                  <!-- sub member limit -->

                  <!-- end selector -->
                  <div v-else-if="!item.hasOwnProperty('file')">
                    <!-- public item's fields -->
                    <v-text-field
                      class="ma-0 pa-0"
                      :label="item.label"
                      filled
                      :rules="item.hasOwnProperty('rule') ? item.rule : []"
                      dense
                      rounded
                      :error-messages="
                        item.hasOwnProperty('error') ? item.error : []
                      "
                      v-model="item.contField"
                    >
                      <v-icon
                        v-if="
                          item.hasOwnProperty('isPublic') && !create && !update
                        "
                        @click="item.isPublic = !item.isPublic"
                        :disabled="
                          disabledVisibleFields.includes(item.id) || false
                        "
                        :color="item.isPublic ? 'blue darken-2' : 'gray'"
                        slot="append"
                      >
                        {{
                          item.hasOwnProperty("isPublic") && item.isPublic
                            ? "mdi-eye"
                            : "mdi-eye-remove"
                        }}
                      </v-icon>
                    </v-text-field>
                  </div>
                  <v-file-input
                    v-else
                    :label="item.label"
                    filled
                    dense
                    :disabled="
                      item.id == 'companyImage' && (create || update)
                        ? true
                        : false
                    "
                    rounded
                    prepend-icon=""
                    :placeholder="
                      item.placeholder != '' ? item.placeholder : ''
                    "
                    :persistent-placeholder="item.placeholder !== ''"
                    :accept="item.type"
                    :rules="item.hasOwnProperty('rule') ? item.rule : []"
                    v-model="item.file"
                  >
                    <v-icon
                      :color="
                        item.placeholder != '' || item.file != null
                          ? 'green'
                          : '#777777'
                      "
                      slot="prepend-inner"
                      >{{
                        item.placeholder != "" ? "mdi-file-document" : "$file"
                      }}
                    </v-icon>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card>

          <!-- Form password update title -->
          <v-card-text
            v-if="!create && !update"
            :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-lext'"
            class="section-title pa-6 pb-2"
            >Cambio de contraseña</v-card-text
          >
          <!-- Form password update content -->
          <v-card v-if="!create && !update" class="mx-5 pa-0" elevation="1">
            <v-form ref="formpassword">
              <v-row class="ma-1 mb-0">
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                  v-for="(item, i) in changePasswrd"
                  :key="i"
                >
                  <v-text-field
                    class="ma-0 pa-0"
                    :label="item.label"
                    filled
                    rounded
                    :rules="verifyfields ? [] : passwordRule"
                    dense
                    v-model="item.contField"
                    :append-icon="item.show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="item.show ? 'text' : 'password'"
                    @click:append="item.show = !item.show"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions class="d-flex justify-end px-5 pt-0 pb-3">
                <v-btn
                  width="150"
                  color="#fdb504"
                  class="rounded-pill font-weight-bold"
                  :disabled="verifyfields"
                  @click="postNewPassword()"
                  :loading="loadingB"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

          <!-- affiliate vinculation -->
          <v-card
            v-if="user.role == 'BRANCH'"
            class="mx-5 mt-5 pa-0"
            elevation="0"
          >
            <AffiliateLink :memberID="miembroAllInfo._id" />
          </v-card>
        </v-card>
      </template>
      <!-- Dialog Card component -->
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { validations } from "@/share/validations.js";
import ImageUploader from "@/components/shared/ImageUploader";
import DateSelector from "../shared/dateSelector";
import { formatDate } from "@/utils/dates";
import { access } from "fs";
import { type } from "os";
import AffiliateLink from "./affiliates/affiliateLink.vue";

export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    route: {
      type: String,
      default: "",
    },
    create: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    miembroAllInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ImageUploader,
    DateSelector,
    AffiliateLink,
  },
  data() {
    return {
      shepherdTour: null,
      loadingB: false,
      passwordButton: true,
      disab: true,
      data: {},
      formDialog: false,
      userData: {},
      publicFields: [],
      errorCapitulo: [],
      profilePicture: "",
      imageToUpdate: null,
      idUser: "",
      items_gener: ["Hombre", "Mujer", "Prefiero no decirlo"],
      items_school: [
        "Primaria",
        "Secundaria",
        "Preparatoria",
        "Carrera Técnica",
        "Licenciatura",
        "Maestría",
        "Doctorado",
      ],
      orgData: [
        {
          //0
          id: "company",
          label: "Nombre de la Compañía / Organización",
          contField: "",
          isPublic: true,
          rule: validations.nameValidation({
            name: "nombre de la empresa",
            required: true,
          }),
          error: [],
        },
        {
          //1
          label: "Nombre",
          id: "name",
          contField: "",
          isPublic: true,
          rule: validations.nameValidation({
            name: "nombre",
            required: true,
          }),
          error: [],
        },
        {
          //2
          label: "Apellido paterno",
          id: "fLastName",
          contField: "",
          isPublic: true,
          rule: validations.nameValidation({
            name: "primer apellido",
            required: true,
          }),
          error: [],
        },
        {
          //3
          label: "Apellido materno",
          id: "mLastName",
          contField: "",
          isPublic: true,
          rule: validations.nameValidation({
            name: "segundo apellido",
            required: true,
          }),
          error: [],
        },
        {
          label: "Correo electrónico",
          id: "email",
          contField: "",
          isPublic: false,
          rule: validations.emailValidation(),
          error: [],
        },
        {
          label: "Verificación de correo electrónico",
          id: "correo_electronico_de_contacto",
          contField: "",
          isPublic: false,
          rule: validations.emailValidation(),
          error: [],
        },
        {
          label: "Teléfono móvil",
          id: "numero_de_celular",
          contField: "",
          isPublic: false,
          rule: validations.phoneValidation({
            required: true,
          }),
          error: [],
        },
        {
          label: "Teléfono fijo (Si aplica)",
          id: "numero_de_telefono",
          contField: "",
          isPublic: false,
          rule: validations.phoneValidation({
            required: false,
          }),
          error: [],
        },
        {
          label: "Código postal",
          id: "codigo_postal",
          isPublic: false,
          contField: "",
          rule: validations.zipCodeValidation({ required: false }),
        },
        {
          label: "Calle",
          id: "calle",
          isPublic: false,
          contField: "",
          rule: validations.generalFValidation({ required: false }),
        },
        // {
        //   label: "Localidad",
        //   id:"localidad",
        //   contField: "",
        //   isPublic:false,
        //   rule: validations.generalFValidation({required:false}),
        // },
        {
          label: "Número exterior",
          id: "num_ext",
          isPublic: false,
          contField: "",
        },
        {
          label: "Número interior (Si aplica)",
          isPublic: false,
          id: "num_int",
          contField: "",
        },
        {
          label: "Colonia",
          id: "colonia",
          isPublic: false,
          contField: "",
          rule: validations.generalFValidation({ required: false }),
        },
        {
          label: "Municipio/Delegación",
          id: "municipio",
          contField: "",
          isPublic: false,
          rule: validations.generalFValidation({ required: false }),
        },
        {
          label: "Estado",
          id: "estado",
          contField: "",
          isPublic: false,
          rule: validations.generalFValidation({ required: false }),
        },
        {
          label: "CURP",
          id: "curp",
          isPublic: false,
          contField: "",
        },
        {
          label: "RFC del representante",
          id: "rfc",
          isPublic: false,
          contField: "",
        },
        {
          label: "Fecha de Nacimiento",
          id: "fecha_de_cumpleanos",
          isPublic: false,
          contField: "",
        },
        {
          label: "Género",
          id: "gender",
          contField: "",
          rule: validations.nameValidation({
            name: "segundo apellido",
            required: false,
          }),
          error: [],
        },
        {
          label: "Grado máximo de estudios",
          id: "maximum_degree_of_studies",
          contField: "",
          rule: validations.nameValidation({
            name: "segundo apellido",
            required: false,
          }),
          error: [],
        },
        {
          label: "Página web",
          id: "pagina_web",
          isPublic: false,
          contField: "",
        },
        {
          label: "Constancia de situación fiscal",
          id: "constanciaSituacionFiscal",
          file: null,
          placeholder: "",
          type: ".pdf",
        },
        {
          label: "INE",
          id: "ine",
          file: null,
          placeholder: "",
          type: ".bpm, .gif, .jpg, .tif, .png, .pdf",
        },
        {
          label: "Comprobante de domicilio",
          id: "comprobanteDomicilio",
          file: null,
          placeholder: "",
          type: ".pdf",
        },
        {
          label: "Logo de empresa",
          id: "companyImage",
          file: null,
          placeholder: "",
          type: ".bpm, .gif, .jpg, .tif, .png",
        },
        // {
        //   label: "Limite de submiembros",
        //   id:"sub_member_limit",
        //   contField:0
        // },
        {
          label: "Miembro desde",
          id: "memberFrom",
          isPublic: false,
          contField: "",
        },
      ],
      defaultVisibleFields: [
        "company",
        "name",
        "lastName",
        "correo_electronico_de_contacto",
        "memberFrom",
      ],
      disabledVisibleFields: [
        "company",
        "name",
        "fLastName",
        "mLastName",
        "correo_electronico_de_contacto",
        "curp",
        "rfc",
      ],
      requiredFields: [
        "company",
        "name",
        "fLastName",
        "mLastName",
        "numero_de_celular",
        "email",
        "correo_electronico_de_contacto",
      ],
      capitulos: {
        label: "Capítulo",
        contField: {
          company: "",
          id: "",
        },
        caplist: [],
      },

      changePasswrd: [
        { label: "Contraseña actual", contField: "", show: false },
        { label: "Nueva contraseña", contField: "", show: false },
        { label: "Verificar nueva contraseña", contField: "", show: false },
      ],
    };
  },
  methods: {
    ...mapActions("member", [
      "memberInformation",
      "updateMember",
      "updatePassword",
      "updateMembershipUSerInfo",
      "fetchMemberCredentials",
    ]),
    ...mapActions("membershipUsers", [
      "fetchMembershipCredentials",
      "fetchMemberInformation",
    ]),
    ...mapActions("user", ["login", "realoadUserData"]),
    ...mapActions("branches", ["getBranches"]),
    ...mapMutations("notification", ["show"]),

    setValueInOrgData(value, id) {
      let findIndex = this.orgData.findIndex((item) => item.id == id);
      //console.log("id: ",id, ' value: ', value ,' index: ', findIndex)
      //this.orgData[findIndex]['isPublic'] = isPublic
      //if(isPublic) this.orgData[findIndex].isPublic = isPublic
      if (
        id == "constanciaSituacionFiscal" ||
        id == "ine" ||
        id == "comprobanteDomicilio" ||
        id == "companyImage"
      ) {
        this.orgData[findIndex].placeholder = value;
      } else {
        this.orgData[findIndex].contField = value || "";
        if (
          this.publicFields.includes(this.orgData[findIndex].id) &&
          this.orgData[findIndex].hasOwnProperty("isPublic")
        ) {
          this.orgData[findIndex].isPublic = true;
        }
      }
    },
    getValueInOrgData(id) {
      let findIndex = this.orgData.findIndex((item) => item.id == id);
      return this.orgData[findIndex].contField;
    },
    async getform() {
      this.loadingB = true;
      this.publicFields = this.user.visibleFields;
      let args; //memberInformation values
      if (this.user.role == "MEMBERSHIP_USER") {
        let aux = (await this.fetchMemberInformation()).data.membershipUser;
        args = aux.memberInformation;
        this.profilePicture = aux.profilePicture;
        this.userData.email = this.user.email;
        this.loadingB = false;
      } else {
        let args1;
        if (this.update) {
          args = this.miembroAllInfo.memberInformation;
          this.capitulos.contField.company = this.miembroAllInfo.branch.company;
          this.idUser = this.miembroAllInfo._id;
        } else {
          args1 = (await this.fetchMemberCredentials()).credential;
          args = (await this.memberInformation()).memberInformation;
          this.profilePicture = args1.branchProfilePicture;
        }
      }
      if (args != undefined) {
        this.data = { memberInformation: args };
        this.userData.email = args.email;
        if (args.email == undefined) {
          this.setValueInOrgData(this.user.email, "email"),
            this.setValueInOrgData(
              args.numero_de_telefono,
              "numero_de_telefono"
            );
        }
        if (args) {
          this.loadingB = false;
          this.setValueInOrgData(args.company, "company");
          this.setValueInOrgData(args.name, "name");
          let fLastName = this.getLastNames(args.lastName).fLastName;
          let mLastName = this.getLastNames(args.lastName).mLastName;
          this.setValueInOrgData(fLastName, "fLastName");
          this.setValueInOrgData(mLastName, "mLastName");
          this.setValueInOrgData(args.gender, "gender");
          this.setValueInOrgData(
            args.maximum_degree_of_studies,
            "maximum_degree_of_studies"
          );
          this.setValueInOrgData(args.numero_de_telefono, "numero_de_telefono");
          this.setValueInOrgData(args.numero_de_celular, "numero_de_celular");
          this.setValueInOrgData(args.email, "email");
          this.setValueInOrgData(
            args.correo_electronico_de_contacto,
            "correo_electronico_de_contacto"
          );
          this.setValueInOrgData(args.codigo_postal, "codigo_postal");
          this.setValueInOrgData(args.calle, "calle");
          // this.setValueInOrgData(args.localidad,'localidad')
          this.setValueInOrgData(args.colonia, "colonia");
          this.setValueInOrgData(args.municipio, "municipio");
          this.setValueInOrgData(args.estado, "estado");
          this.setValueInOrgData(args.curp, "curp");
          this.setValueInOrgData(
            args.fecha_de_cumpleanos,
            "fecha_de_cumpleanos"
          );
          this.setValueInOrgData(args.num_ext, "num_ext");
          this.setValueInOrgData(args.num_int, "num_int");
          this.setValueInOrgData(args.pagina_web, "pagina_web");
          this.setValueInOrgData(args.rfc, "rfc");
          let dateChain = "";
          if (args.hasOwnProperty("memberFrom")) {
            dateChain = args.memberFrom.split("T")[0];
          }
          console.log(dateChain);
          this.setValueInOrgData(dateChain, "memberFrom");

          let constanciaSituacionFiscal = args.hasOwnProperty(
            "constanciaSituacionFiscal"
          )
            ? args.constanciaSituacionFiscal.split("/")[
                args.constanciaSituacionFiscal.split("/").length - 1
              ]
            : "";
          let ine = args.hasOwnProperty("ine")
            ? args.ine.split("/")[args.ine.split("/").length - 1]
            : "";
          let comprobanteDomicilio = args.hasOwnProperty("comprobanteDomicilio")
            ? args.comprobanteDomicilio.split("/")[
                args.comprobanteDomicilio.split("/").length - 1
              ]
            : "";
          let companyImage = args.hasOwnProperty("companyImage")
            ? args.companyImage.split("/")[
                args.companyImage.split("/").length - 1
              ]
            : "";

          this.setValueInOrgData(
            constanciaSituacionFiscal == "NOT_ASSIGNED"
              ? ""
              : constanciaSituacionFiscal,
            "constanciaSituacionFiscal"
          );
          this.setValueInOrgData(ine == "NOT_ASSIGNED" ? "" : ine, "ine");
          this.setValueInOrgData(
            companyImage == "NOT_ASSIGNED" ? "" : companyImage,
            "companyImage"
          );
          this.setValueInOrgData(
            comprobanteDomicilio == "NOT_ASSIGNED" ? "" : comprobanteDomicilio,
            "comprobanteDomicilio"
          );
        }
      } else {
        this.loadingB = false;
      }
    },
    getDateFormat(date) {
      if (date == undefined || date == null) {
        return "";
      }
      if (date.match(/[A-Z]|[a-z]|/g).some((e) => e !== "")) {
        return "";
      }
      return date;
    },
    setform() {
      
      let memberform = this.getValueInOrgData("memberFrom")? new Date(this.getValueInOrgData("memberFrom")).toISOString() : new Date().toISOString()
      this.data = {
        memberInformation: {
          company: this.getValueInOrgData("company") || "N/D",
          name: this.getValueInOrgData("name") || "N/D",
          lastName:
            this.getValueInOrgData("fLastName") +
              " " +
              this.getValueInOrgData("mLastName") || "N/D",
          gender: this.getValueInOrgData("gender") || "N/D",
          maximum_degree_of_studies:
            this.getValueInOrgData("maximum_degree_of_studies") || "N/D",
          numero_de_telefono:
            parseInt(this.getValueInOrgData("numero_de_telefono")) || "",
          numero_de_celular:
            parseInt(this.getValueInOrgData("numero_de_celular")) || "",
          email: this.getValueInOrgData("email") || "N/D",
          correo_electronico_de_contacto:
            this.getValueInOrgData("correo_electronico_de_contacto") || "N/D",
          codigo_postal:
            parseInt(this.getValueInOrgData("codigo_postal")) || "",
          calle: this.getValueInOrgData("calle") || "N/D",
          localidad: "N/D",
          colonia: this.getValueInOrgData("colonia") || "N/D",
          municipio: this.getValueInOrgData("municipio") || "N/D",
          memberFrom: memberform,
          estado: this.getValueInOrgData("estado") || "N/D",
          curp: this.getValueInOrgData("curp") || "N/D",
          fecha_de_cumpleanos:
            this.getValueInOrgData("fecha_de_cumpleanos") || "N/D",
          num_ext: this.getValueInOrgData("num_ext") || "",
          num_int: this.getValueInOrgData("num_int") || "",
          pagina_web: this.getValueInOrgData("pagina_web") || "N/D",
          rfc: this.getValueInOrgData("rfc") || "N/D",
          sub_member_limit: 0,
        },
      };
      if (this.create) {
        try {
          this.data.branch = JSON.parse(
            JSON.stringify(this.capitulos.contField)
          );
        } catch {
          this.data.branch = {};
        }
      }
      return this.data;
      // }
    },

    async updateMemberInfomation() {
      let accept = 0;
      this.loadingB = true;
      //Validate if chapter field is empty
      if (
        this.capitulos.contField != undefined &&
        this.capitulos.contField.company != "" &&
        !this.$route.fullPath.includes("member")
      ) {
        this.errorCapitulo = [];
        accept++;
      } else {
        this.errorCapitulo.push("Este campo es obligatorio");
        setTimeout(() => {
          this.errorCapitulo = [];
        }, 3000);
      }
      //Validate if required fields are filled
      this.orgData.forEach((e) => {
        if (
          this.requiredFields.includes(e.id) &&
          (e.contField == "" || e.contField == "N/D")
        ) {
          e.error.push("Este campo es obligatorio");
          setTimeout(() => {
            e.error = [];
          }, 3000);
        } else if (this.requiredFields.includes(e.id) && e.contField != "") {
          e.error = [];
          accept++;
        }
      });
      //validate if both email direction fields are equals
      if (
        this.getValueInOrgData("email") ===
        this.getValueInOrgData("correo_electronico_de_contacto")
      ) {
        accept++;
      } else {
        this.orgData[
          this.orgData.findIndex(
            (e) => e.id == "correo_electronico_de_contacto"
          )
        ].error.push("Correo invalido");
        setTimeout(() => {
          this.orgData[
            this.orgData.findIndex(
              (e) => e.id == "correo_electronico_de_contacto"
            )
          ].error = [];
        }, 3000);
      }

      if (
        accept == 9 ||
        ((this.user.role == "MEMBER" ||
          this.user.role == "MEMBERSHIP_USER" ||
          this.user.role == "BRANCH") &&
          accept == 8)
      ) {
        this.loadingB = true;
        let visible = {
          visibleFields: this.getPublics(),
        };

        //update public fields in members and affiliates
        if (this.user.role === "MEMBERSHIP_USER") {
          await this.axios.put("/membership-users/visible-fields", visible);
        } else if (this.user.role === "MEMBER") {
          await this.axios.put("/members/visible-fields", visible);
        }
        // end update

        if (this.user.role == "MEMBERSHIP_USER") {
          try {
            let conf = await this.updateMembershipUSerInfo(this.setform());
            await this.handleImageUpdate();
            await this.updateFiles();
            if (conf.status) {
              this.realoadUserData({ email: this.user.email });
              this.$router.go();
            }
          } catch (error) {
            this.loadingB = false;
            return error;
          }
        } else if (this.update || this.create) {
          //create or update member into in "Mis Miembros" section
          if (this.update) await this.updateFiles();
          let confirm = this.update ? "update" : "create";
          this.$emit(
            "save",
            this.setform(),
            this.miembroAllInfo._id,
            confirm,
            this.updateFiles
          );
        } else {
          //update member info in "MEMBER" type users
          try {
            let conf = await this.updateMember(this.setform());
            await this.updateFiles();
            await this.handleImageUpdate();
            console.log(conf);
            if (conf.status) {
              this.realoadUserData({ email: this.user.email });
              await this.fetchMemberCredentials();
              this.loadingB = false;
              this.show({
                text: "Datos actualizados exitosamente!",
                color: "success",
                time: 3000,
              });
            }
          } catch (error) {
            this.show({
              text: "Error al cargar los datos",
              color: "error",
              time: 3000,
            });
            this.loadingB = false;
            return error;
          }
        }
        setTimeout(() => {
          this.formDialog = false;
        }, 1000);
      }
      this.loadingB = false;
    },

    handleDate(date, label) {
      const e = this.orgData.find((e) => e.label == label);
      this.setValueInOrgData(date, e.id);
    },

    getPublics() {
      let visibleFields = [];
      this.orgData.forEach((e) => {
        if (e.hasOwnProperty("isPublic") && e.isPublic) {
          visibleFields.push(e.id);
        }
      });
      var a = visibleFields;
      var b = this.defaultVisibleFields;
      var union = [...new Set([...a, ...b])];
      return union;
    },

    async postNewPassword() {
      this.loadingB = true;
      this.userData.password = this.changePasswrd[0].contField;
      let reqest = {};
      try {
        let conf = await this.login(this.userData);
        if (conf.status) {
          if (
            this.changePasswrd[1].contField === this.changePasswrd[2].contField
          ) {
            reqest.password = this.changePasswrd[2].contField;
            reqest.token = localStorage.getItem("USER_TOKEN");
            let pass = await this.updatePassword(reqest);
            if (pass.status) {
              console.log("contraseña actualizada exitosamente");
              this.show({
                text: "Contraseña actualizada exitosamente",
                color: "success",
                time: 3000,
              });
              this.$refs.formpassword.reset();
            } else this.errorMessage(1);
          } else this.errorMessage(1);
        } else this.errorMessage(3);
      } catch (error) {
        this.errorMessage(2);
        return error;
      } finally {
        this.loadingB = false;
      }
    },
    getLastNames(lastn) {
      var nam = lastn.split(" ");
      if (nam.length == 2){
        return { fLastName: nam[0], mLastName: nam[1] };
      }else if (nam.length == 3){
        return { fLastName: nam[0]+" "+nam[1], mLastName: nam[2]};
      }else if (nam.length == 4){
        return { fLastName: nam[0]+" "+nam[1], mLastName: nam[2]+" "+nam[3]};
      }
    },

    errorMessage(type) {
      if (type == 1) {
        this.show({
          text: "Error, Verifique los datos",
          color: "error",
          time: 3000,
        });
      } else if (type == 2) {
        this.show({
          text: "Error, solicitud fallida",
          color: "error",
          time: 3000,
        });
      } else if (type == 3) {
        this.show({
          text: "Error, contraseña actual incorrecta",
          color: "error",
          time: 3000,
        });
      }
    },
    async updateFiles(id = "") {
      if (
        this.orgData[23].file != null ||
        this.orgData[22].file != null ||
        this.orgData[21].file != null ||
        this.orgData[24].file != null
      ) {
        var formdat = new FormData();
        formdat.append("constanciaSituacionFiscal", this.orgData[21].file);
        formdat.append("ine", this.orgData[22].file);
        formdat.append("comprobanteDomicilio", this.orgData[23].file);
        formdat.append("companyImage", this.orgData[24].file);

        const idMember = id != "" ? id : this.miembroAllInfo._id;
        let response;
        if (this.user.role == "HEAD_OFFICE") {
          response = await this.axios.put(
            "/members/head-office/docs/" + idMember,
            formdat
          );
        } else if (this.user.role == "BRANCH") {
          response = await this.axios.put(
            "/branches/member/docs/" + idMember,
            formdat
          );
        } else if (this.user.role == "MEMBERSHIP_USER") {
          response = await this.axios.put("/membership-users/docs", formdat);
        } else {
          response = await this.axios.put("/members/docs", formdat);
        }
        if (response.status == 200) {
          console.log("Datos cargados con exito");
          this.orgData[22].file = null;
          this.orgData[23].file = null;
          this.orgData[24].file = null;
          this.orgData[25].file = null;
        }
      }
    },

    handleImage(file, resetState) {
      this.imageToUpdate = file;
      // resetState();
    },
    async handleImageUpdate() {
      if (this.imageToUpdate != null) {
        const formData = new FormData();
        formData.append("file", this.imageToUpdate);
        const response = await this.axios.put(
          "/users/update-profile-picture",
          formData
        );
        if (response.status === 200) {
          this.show({
            color: "primary",
            text: "¡La imagen se ha actualizado correctamente!",
          });
        }
      }
    },

    outsideDialog() {
      this.disab = false;
      this.orgData.map((data) => {
        data.contField = "";
      });

      this.changePasswrd.map((data) => {
        data.contField = "";
      });
      this.capitulos.contField = {};

      // this.$refs.formpassword.reset()
      // this.$refs.formuser.reset()
    },

    editBranch(v) {
      this.capitulos.contField = this.capitulos.caplist.filter(
        (e) => e.company == v
      )[0] || { company: "", id: "" };
    },
    persistentHintRule(item) {
      return this.requiredFields.includes(item.id)
        ? "Este campo es obligatorio"
        : "";
    },

    async editContentBranches() {
      this.setValueInOrgData("2000-01-01", "memberFrom");
      this.setValueInOrgData("2000-01-01", "fecha_de_cumpleanos");
      if (this.capitulos.caplist.length == 0) {
        this.capitulos.caplist = (await this.getBranches()).branches.map(
          (e) => {
            return { _id: e._id, company: e.company };
          }
        );
      }
    },
  },

  computed: {
    ...mapState("user", ["user"]),

    passwordRule() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) ||
          "La contraseña debe tener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) ||
          "La contraseña debe tener al menos una letra minúscula",
        (v) =>
          /[^A-Za-z0-9]/.test(v) ||
          "La contraseña debe tener al menos un caracter especial",
      ];
    },
    verifyDataFields() {
      let aux = this.requiredFields.map((e) => this.getValueInOrgData(e) || "");
      aux.push(
        this.capitulos.contField != undefined
          ? this.capitulos.contField._id
          : ""
      );
      let resp = aux.includes("");
      if (aux.includes("N/D")) resp = true;
      return resp;
    },
    verifyfields: function () {
      if (
        this.changePasswrd[0].contField == "" &&
        this.changePasswrd[1].contField == "" &&
        this.changePasswrd[2].contField == ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    setBranches() {
      let obj = this.capitulos.caplist.map((e) => e.company);
      return obj;
    },
  },

  created() {
    if (this.$route.query.needsPayment) {
      this.$nextTick(() => {
        this.shepherdTour = this.$shepherd({
          useModalOverlay: true,
        });

        this.shepherdTour.addStep({
          id: "step-1",
          text: "Por favor actualiza tu información para poder continuar",
          attachTo: {
            element: ".step-1",
            on: "bottom",
          },
        });
        this.shepherdTour.start();
      });
    }
  },
};
</script>
<style scoped>
.section-title {
  font-size: 18px;
  font-weight: 900;
  color: black;
}
</style>

