import { render, staticRenderFns } from "./affiliateLink.vue?vue&type=template&id=d0893210&scoped=true"
import script from "./affiliateLink.vue?vue&type=script&lang=js"
export * from "./affiliateLink.vue?vue&type=script&lang=js"
import style0 from "./affiliateLink.vue?vue&type=style&index=0&id=d0893210&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0893210",
  null
  
)

export default component.exports