<template>
  <v-dialog v-model="dialog" :width="dimentions.width" persistent @click:outside="_closeDialog">
    <v-card color="white">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="10" md="7" class="mt-8 d-flex justify-center" >
            <v-img
              v-if="!hideImage"
              class=""
              src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
              max-width="220"
              height="auto"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row v-if="!hideName">
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold headline mb-0">
              <slot name="alertName">
                Cerrar sesión
              </slot>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <slot name="alertContent">
            <p class="title text-center">
                ¿Está seguro que desea guardar cambios?
              </p>
            </slot>
          </v-col>
        </v-row>
        <v-row
          class="d-flex pb-2 mt-5"
          :class="
            $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
          "
        >
          <v-btn 
            v-if="!hideButton"
            class="font-weight-bold black--text mx-2"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="_closeDialog"
          >
          <slot name="cancelButton">
              Cancelar
            </slot>
          </v-btn>
          <v-btn
            class="font-weight-bold black--text mx-2"
            color="accent"
            :disabled="disableContinueButton"
            rounded
            elevation="0"
            @click="_executeFunction"
          >
            <slot name="button">
              Aceptar
            </slot>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
props:{
  dialog:{
    type:Boolean,
    default: false
  },
  hideName:{
    type: Boolean,
    default: false
  },
  hideImage:{
    type: Boolean,
    default: false
  },
  hideButton:{
    type: Boolean,
    default: false
  },
  disableContinueButton:{
    type:Boolean,
    default: false
  },
  closeDialogOnContinue:{
    type: Boolean,
    default: true
  },
  dimentions: {
    type: Object,
    default: (() => ({
      width:600,
    }))
  }
},
data() {
  return {
    openDialog: this.dialog
  }
},
methods:{
  _executeFunction(){
    this.$emit('save')
    if (this.closeDialogOnContinue) {
      setTimeout(() => {
        this._closeDialog()
      }, 500);
    }
  },
  _closeDialog(){
    this.$emit('discard')
  }
}
}
</script>