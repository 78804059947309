import Vue from 'vue';
import Endpoints from '../../share/Endpoints';

export const fetchMembershipCredentials = async ({commit})=>{
  try {
    let credential = await Vue.axios({
      method: 'GET',
      url: Endpoints.membershipUsers,
    });
    commit('setCredentials', credential.data)
    return {status: true, credential: credential.data.credentialInformation}
  }catch(err){
    return err
  }
}

export const fetchMemberInformation = async ({commit})=>{
  try {
    let data = await Vue.axios({
      method:'GET',
      url:'/membership-users/information'
    });
    return {status: true, data: data.data}
  } catch (error) {
    return error
  }
}