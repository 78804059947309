//export colors to be used in vue componets
export const colors = {
  primary: '#24b2da',
  primaryVariant: '#1b273e',
  diabledText: '#8c929e',
  primaryVariantClear: '#d6f6ff',
  active: '#9bf4d5',
  secondary: '#ffb500',
  secondaryVariant: '#fce09e',
  gray: '#d8d8d8',
  background: '#f9fafc',
  surface: '#ffffff',
  error: '#fcbd9e',
  contrastError: '#c46130',
};