import Vue from "vue";
import Endpoints from "@/share/Endpoints"

export const fetchDebt = async ({commit}, debts) => {
  try {
    const {data} = await Vue.axios({
      url: Endpoints.debtAmount,
      method: 'GET'
    })

    return { status: true, data: data.debts}
  } catch (error) {
    return { status: false, message: error }
  }
}
