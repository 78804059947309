export function setAffiliates(state, affiliates) {
  state.affiliates = affiliates.data.membershipUsers;
}
export function addAffiliate(state, affiliate) {
  state.affiliates.push(affiliate);
}
export function updateAffiliate(state, affiliate) {
  state.affiliates = state.affiliates.map(aff => {
    if (aff._id === affiliate._id) {
      return affiliate;
    }
    return aff;
  });
}
export function deleteAffiliate(state, affiliate) {
  state.affiliates.forEach(element => {
    if (element._id === affiliate._id) {
      element.validationStatus = "DELETED";
    }
  });
}