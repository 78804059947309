export function setOrganizationAffiliationsPaginated(state, organizationAffiliationsPaginated) {
  // Comprobar si el estado tiene datos de affiliaciones
  if (state.organizationAffiliationsPaginated.totalDocs==null) {
    state.organizationAffiliationsPaginated = organizationAffiliationsPaginated;
  } else {
    state.organizationAffiliationsPaginated.affiliations = state.organizationAffiliationsPaginated.affiliations.concat(organizationAffiliationsPaginated.affiliations);
    state.organizationAffiliationsPaginated.totalDocs = organizationAffiliationsPaginated.totalDocs;
    state.organizationAffiliationsPaginated.limit = organizationAffiliationsPaginated.limit;
    state.organizationAffiliationsPaginated.totalPages = organizationAffiliationsPaginated.totalPages;
    state.organizationAffiliationsPaginated.page = organizationAffiliationsPaginated.page;
    state.organizationAffiliationsPaginated.pagingCounter = organizationAffiliationsPaginated.pagingCounter;
    state.organizationAffiliationsPaginated.hasPrevPage = organizationAffiliationsPaginated.hasPrevPage;
    state.organizationAffiliationsPaginated.hasNextPage = organizationAffiliationsPaginated.hasNextPage;
    state.organizationAffiliationsPaginated.prevPage = organizationAffiliationsPaginated.prevPage;
    state.organizationAffiliationsPaginated.nextPage = organizationAffiliationsPaginated.nextPage;
  }
}

export function setOrganizationAffiliations(state, organizationAffiliations) {
  state.organizationAffiliations = organizationAffiliations;
}

export function clearOrganizationAffiliationsPaginated(state) {
  state.organizationAffiliationsPaginated = {
    affiliations: [],
    totalDocs: null,
    limit: 8,
    totalPages: null,
    page: 1,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: null
  };
}

export function setAffiliationSelected(state, affiliationSelected) {
  state.affiliationSelected = affiliationSelected;
}

export function setAffiliationMicrositeData(state, affiliationMicrositeData) {
  state.affiliationMicrositeData = affiliationMicrositeData;
}

export function setAffiliationFaqs(state, affiliationFaqs) {
  state.affiliationFaqs = affiliationFaqs;
}