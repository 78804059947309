export function getExcecutives(state, excecutives) {
  state.excecutives = excecutives.executives;
}
export function createExecutive(state, executive) {
  state.excecutives.push(executive.executive);
}
export function updateExecutive(state, executive) {
  let index = state.excecutives.findIndex(item => item._id === executive._id);
  state.excecutives[index] = executive;
}
export function deleteExecutive(state, id) {
  let index = state.excecutives.findIndex(item => item._id === id);
  state.excecutives[index].status = "DELETED";
}