import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDBgOjYNEM4h-0iFLx4nI1P5fCrUVTTDnk',
    libraries: 'places',
    region: 'MX',
    language: 'es',
  },
});