export default {
  loggedIn: false,
  user: {},
  token: '',
  indicators: {
    totalItems: 0,
    unassignedItems: 0,
    assignedItems: 0,
    soldItems: 0,
    orderPaymentPendingItems: 0
  },
}