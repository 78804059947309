<template>
  <v-container fluid class="pa-0">
    <v-row class="d-flex justify-center pa-5 pa-md-10 gradient-top">
      <!--
            <v-col cols="10" md="7" class="pa-7">
                <v-row>
                    <div class="grid-container">
                        <img id="user1" draggable="false" src="@/assets/images/landing/virtualAttendees/user1.jpg">
                        <img id="user2" draggable="false" src="@/assets/images/landing/virtualAttendees/user2.jpg">
                        <img id="user3" draggable="false" src="@/assets/images/landing/virtualAttendees/user3.jpg">
                        <img id="user4" draggable="false" src="@/assets/images/landing/virtualAttendees/user4.jpg">
                        <img id="user5" draggable="false" src="@/assets/images/landing/virtualAttendees/user5.jpg">
                        <img id="user6" draggable="false" src="@/assets/images/landing/virtualAttendees/user6.jpg">
                        <img id="user7" draggable="false" src="@/assets/images/landing/virtualAttendees/user7.jpg">
                        <img id="user8" draggable="false" src="@/assets/images/landing/virtualAttendees/user8.jpg">
                        <img id="user9" draggable="false" src="@/assets/images/landing/virtualAttendees/user9.jpg">
                        <img id="user10" draggable="false" src="@/assets/images/landing/virtualAttendees/user10.jpg">
                        <img id="user11" draggable="false" src="@/assets/images/landing/virtualAttendees/user11.jpg">
                        <img id="home-dashboard" draggable="false" style="transform: scale(1.07);" src="@/assets/images/landing/Tablero-demo.svg">
                    </div>
                </v-row>
            </v-col>
            <v-col class="text-end" cols="12" md="5">
                <h1 class="display-1 font-weight-black wDarkerBlue--text">La forma inteligente de vender y administrar eventos profesionales.</h1>
                <v-expand-x-transition>
                <p class="subtitle-1 my-6">WeChamber es la plataforma que te ayudará a crear tu sitio, cobrar entradas, facturar, generar constancias, tener tableros de control y más...</p>
                </v-expand-x-transition>
                <v-btn style="text-transform:none" class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Comenzar</v-btn>
            </v-col>-->
      <v-col
        cols="12"
        md="5"
        :class="$vuetify.breakpoint.xsOnly ? 'order-2 pt-6' : 'order-1 pt-10'"
      >
        <h1
          class="font-weight-black wDarkerBlue--text"
          :class="$vuetify.breakpoint.xlOnly ? 'display-3' : 'display-2'"
        >
          <span class="secondary--text">Automatiza</span> las membresías y
          eventos de tu organización.
        </h1>
        <v-expand-x-transition>
          <p
            class="my-6"
            :class="$vuetify.breakpoint.lgAndUp ? 'headline' : 'title'"
          >
            <span class="font-weight-bold">Software en la nube</span> para
            gestionar pagos en línea, facturación, diplomas, estadística y
            más...
          </p>
        </v-expand-x-transition>
        <p class="mt-5 mb-0 font-weight-bold" style="font-size:16px" >
          <span> <span class="secondary--text">Comienza gratis</span>, los primeros 50 registros van por nuestra cuenta.</span>
        </p>
        <v-btn
          to="/registro"
          style="text-transform: none"
          class="title accent wDarkerBlue--text font-weight-black mt-3"
          elevation="5"
          rounded
          dark
          x-large
          >Comenzar</v-btn>
      </v-col>
      <v-col
        cols="10"
        md="7"
        class="pa-7"
        :class="$vuetify.breakpoint.xsOnly ? 'order-1' : 'order-2'"
      >
        <v-row>
          <div class="grid-container">
            <img
              id="user1"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user1.png"
            />
            <img
              id="user2"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user2.png"
            />
            <img
              id="user3"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user3.png"
            />
            <img
              id="user4"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user4.png"
            />
            <img
              id="user5"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user5.png"
            />
            <img
              id="user6"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user6.png"
            />
            <img
              id="user7"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user7.png"
            />
            <img
              id="user8"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user8.png"
            />
            <img
              id="user9"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user9.png"
            />
            <!--<img
              id="user10"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user10.png"
            />
            <img
              id="user11"
              draggable="false"
              src="@/assets/images/landing/virtualAttendees/user11.png"
            />-->
            <!--<img id="home-dashboard" draggable="false" style="transform: scale(1.07);" src="@/assets/images/landing/Tablero-demo.svg">-->
            <embed
              id="home-dashboard"
              type="image/svg+xml"
              style="transform: scale(1.07); border-radius: 10px"
              :src="require('@/assets/images/landing/Tablero-demo.svg')"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-15">
      <v-col cols="10" offset="1">
        <v-row>
          <v-col
            cols="12"
            md="4"
            lg="5"
            offset-lg="1"
            class="d-flex justify-center"
          >
            <v-img
              src="@/assets/images/landing/computer_seo.svg"
              width="550"
              height="auto"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12" md="8" lg="6" class="d-flex align-center">
            <p
              class="font-weight-black wDarkerBlue--text pl-md-8"
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'display-2'
                  : 'headline text-center'
              "
            >
              Digitaliza tu <span class="secondary--text">asociación</span>,
              cámara empresarial, colegio profesional, instituto o academia.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--<v-row justify="end">
            <v-img src="@/assets/images/landing/pipeline-intro.svg" max-width="250" height="auto" style="transform: scale(2)"></v-img>
        </v-row>-->
    <!--<v-row>
            <v-col cols="12">
                <v-btn v-if="$vuetify.breakpoint.xsOnly" fab color="secondary" dark>
                    <v-icon>far fa-comment-alt</v-icon>
                </v-btn>
                <v-btn v-else class="mx-5" color="secondary" rounded dark large>
                    <span class="font-weight-bold"><i class="far fa-comment-alt pr-1"></i>Chat</span>
                </v-btn>
            </v-col>
        </v-row>-->
    <!--
        <v-row class="pt-4" justify="center">
            <v-card
                flat
                tile
                v-if="$vuetify.breakpoint.smAndDown"
            >
                <v-window
                v-model="onboarding"
                class="rounded-xl"
                >
                <v-window-item
                    v-for="(item,i) in benefits"
                    :key="i"
                    class="ma-6"
                >
                    <v-card
                    height="350"
                    width="290"
                    color="wLightGray"
                    elevation="12"
                    class="on-hover pa-7 rounded-xl"
                    >
                        <v-row justify="center" class="mt-4">
                            <v-img contain max-height="70" max-width="100" :src="item.src"></v-img>
                        </v-row>
                        <p class="headline text-center font-weight-bold mt-5 mb-0">
                          {{item.title}}
                        </p>
                        <v-card-text>
                            <p>{{item.text}}</p>
                        </v-card-text>
                    </v-card>
                </v-window-item>
                </v-window>
                <v-card-actions class="justify-space-between">
                    <v-row justify="center" class="ma-2">
                        <v-item-group
                            v-model="onboarding"
                            class="text-center"
                            mandatory
                        >
                            <v-item
                            v-for="n in benefits.length"
                            :key="`btn-${n}`"
                            v-slot="{ active, toggle }"
                            >
                            <v-btn
                            x-large
                                :input-value="active"
                                icon
                                @click="toggle"
                                :color="active? 'wDarkerBlue':'wLightBlue'"
                            >
                                <v-icon>mdi-record</v-icon>
                            </v-btn>
                            </v-item>
                        </v-item-group>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-col v-else cols="12">
                <v-row class="d-flex justify-center">
                    <div v-for="(item, i) in benefits" :key="i">
                        <v-hover v-slot="{ hover }">
                            <v-card
                            height="350"
                            width="320"
                            color="wLightGray"
                            :elevation="hover ? 12 : 0"
                            :class="{ 'on-hover': hover }"
                            class="pa-8 rounded-xl ma-3"
                            >
                                <v-row justify="center" class="mt-4">
                                    <v-img contain max-height="70" max-width="100" :src="item.src"></v-img>
                                </v-row>
                                <v-card-title class="pt-6 d-flex justify-center">
                                    <h3>{{item.title}}</h3>
                                </v-card-title>
                                <v-card-text>
                                    <p>{{item.text}}</p>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </div>
                </v-row>
            </v-col>
        </v-row>-->
    <v-row class="my-10">
      <v-col cols="10" offset="1" class="mb-7">
        <p
          class="font-weight-black wDarkerBlue--text"
          :class="
            $vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline text-center'
          "
        >
          Herramientas que <span class="secondary--text">automatizan</span> tu
          operación.
        </p>
      </v-col>
      <v-col cols="10" offset="1" class="mb-10">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            class="pa-7"
            v-for="(item, index) in wechamberTools"
            :key="index"
          >
            <v-row justify="center">
              <v-icon size="140" :color="item.iconColor">{{
                item.icon
              }}</v-icon>
            </v-row>
            <v-row justify="center">
              <p class="secondary--text title font-weight-bold mb-0">
                {{ item.title }}
              </p>
            </v-row>
            <v-row justify="center">
              <p class="text-center">{{ item.text }}</p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" offset="1" class="d-flex justify-end">
        <v-btn
          to="/registro"
          style="text-transform: none"
          class="title wDarkerBlue--text font-weight-black mt-5"
          color="accent"
          elevation="5"
          rounded
          x-large
          >Quiero comenzar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-row justify="end">
          <v-col cols="6">
            <v-img
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'phone-one-mobile'
                  : 'phone-one-web'
              "
              src="@/assets/images/landing/iphonemockup.png"
            >
            </v-img>
          </v-col>
          <v-col cols="6">
            <!--<v-img v-if="$vuetify.breakpoint.lgAndUp" src="@/assets/images/landing/pipeline-phones.svg" style="position: absolute; transform: scale(1.9) translateX(35%)"></v-img>-->
            <v-img
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'phone-two-mobile'
                  : 'phone-two-web'
              "
              src="@/assets/images/landing/iphonemockup4.svg"
              style="transform: scale(1.17)"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col align-self="end" class="px-8 px-md-12 pt-md-12" cols="12" md="6">
        <v-row>
          <div class="ma-md-16 pa-2 mb-sm-8 mx-4">
            <v-row justify="end">
              <h1 class="font-weight-black wDarkerBlue--text">
                <span class="secondary--text">Automatiza</span> lo repetitivo,
              </h1>
            </v-row>
            <v-row justify="end">
              <h1 class="font-weight-black wDarkerBlue--text">
                humaniza lo importante!!
              </h1>
            </v-row>
            <!--<v-row justify="end">
                            <v-btn class="accent text-capitalize wDarkerBlue--text font-weight-black" elevation="5" rounded dark>Comenzar</v-btn>
                        </v-row>-->
          </div>
        </v-row>
        <v-row :justify="$vuetify.breakpoint.lgAndUp ? 'center' : 'end'">
          <v-img
            src="@/assets/images/landing/pipeline-arrow.svg"
            max-width="200"
            height="auto"
          ></v-img>
        </v-row>
      </v-col>
      <v-row>
        <v-col>
          <div
            class="
              d-block
              pa-2
              border-radius-sectionBlue
              text-center
              wDarkerBlue
              white--text
            "
          >
            <v-row>
              <v-col align-self="start" cols="12" md="6"> </v-col>
              <v-col
                align-self="end"
                align="start"
                class="pa-12"
                cols="12"
                md="6"
              >
                <div class="pa-md-12">
                  <!--<p>Deja que nuestra plataforma trabaje para ti. Ocúpate de lo importante.</p>-->
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="mt-15">
      <v-col cols="12">
        <!--<p class="headline font-weight-bold text-center">Organizadores que han confiado en nosotros</p>-->
        <h1 class="font-weight-black wDarkerBlue--text text-center">
          Organizaciones que han <span class="secondary--text">confiado </span>en nosotros
        </h1>
      </v-col>
      <v-col cols="12" class="ma-md-6">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center">
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/adiat.png"
                contain
              ></v-img>
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/amivtac.png"
                contain
              ></v-img>
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/ammje.jpg"
                contain
              ></v-img>
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/ampi.png"
                contain
              ></v-img>
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/canaco.jpg"
                contain
              ></v-img>
              <v-img
                class="organizations mx-3"
                min-width="120"
                max-width="120"
                src="@/assets/images/landing/organization/femmede.png"
                contain
              ></v-img>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col cols="12" class="mt-3 mt-sm-0">
        <p class="text-center grey--text caption mb-0">
          EMPRESA ACELERADA EN
          <span class="secondary--text">SILICON VALEY</span>
        </p>
      </v-col>
      <!--<v-col cols="12">
                <p class="text-center grey--text display-1 font-weight-black mb-0">Manos</p>
                <p class="text-center grey--text display-1 font-weight-black mb-0">Accelerator</p>
                <p class="text-center grey--text caption">By Google LaunchPad</p>
            </v-col>-->
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          max-width="250"
          height="auto"
          src="@/assets/images/landing/manos-acelerator-logo.png"
        >
        </v-img>
      </v-col>
      <v-col cols="12" class="pt-0">
        <p class="text-center grey--text caption">By Google LaunchPad</p>
      </v-col>
    </v-row>
    <landing-footer></landing-footer>
    <!--
        <v-row :class="$vuetify.breakpoint.smAndDown?'':'ma-12'" justify="center">
              <v-card
                    flat
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-window
                    v-model="qtyLicenses"
                    class="rounded-xl"
                    >
                    <v-window-item
                        v-for="(item,i) in licenses"
                        :key="i"
                        class="ma-6"
                    >
                        <v-card
                        height="auto"
                        width="260"
                        :color="item.color"
                        class="rounded-xl on-hover"
                        elevation="12"
                        >
                            <v-card-subtitle class="text-end pb-0 pr-8">
                                <h3 class="font-weight-black wDarkerBlue--text">WeChamber</h3>
                            </v-card-subtitle>
                            <v-card-subtitle class="text-end pt-0 pr-8" :class="`${item.textColor}--text`">
                                <h1 class="font-weight-black">{{item.name}}</h1>
                            </v-card-subtitle>
                            <v-card-text class="px-0">
                                <v-col class="py-0"><span>- {{item.records}}</span></v-col>
                                <v-col class="py-0"><span>- {{item.events}}</span></v-col>
                                <v-list flat :color="item.color" class="ma-0 pa-0">
                                    <v-list-item-group
                                    >
                                        <v-list-item
                                        v-for="(item, i) in item.includes"
                                        :key="i"
                                        >
                                            <v-img class="mr-2" max-height="20" max-width="20" :src="item.icon"></v-img>
                                            <span> {{item.text}}</span>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-4">
                                <v-btn class="text-capitalize accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>{{item.btn}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-window-item>
                    </v-window>
                    <v-card-actions class="justify-space-between">
                        <v-row justify="center" class="ma-2">
                            <v-item-group
                                v-model="qtyLicenses"
                                class="text-center"
                                mandatory
                            >
                                <v-item
                                v-for="n in licenses.length"
                                :key="`btn-${n}`"
                                v-slot="{ active, toggle }"
                                >
                                <v-btn
                                x-large
                                    :input-value="active"
                                    icon
                                    @click="toggle"
                                    :color="active? 'wDarkerBlue':'wLightBlue'"
                                >
                                    <v-icon>mdi-record</v-icon>
                                </v-btn>
                                </v-item>
                            </v-item-group>
                        </v-row>
                    </v-card-actions>
                </v-card>
            <v-col v-else cols="12" class="pa-7">
                <v-row class="d-flex justify-center">
                    <div v-for="(item, i) in licenses" :key="i">
                        <v-hover v-slot="{ hover }">
                            <v-card
                            height="auto"
                            width="260"
                            :color="item.color"
                            :elevation="hover ? 12 : 0"
                            :class="{ 'on-hover': hover }"
                            class="rounded-xl ma-3"
                            >
                                <div class="pa-3">

                                    <v-card-subtitle class="text-end pb-0 pr-8">
                                        <h3 class="font-weight-black wDarkerBlue--text">WeChamber</h3>
                                    </v-card-subtitle>
                                    <v-card-subtitle class="text-end pt-0 pr-8" :class="`${item.textColor}--text`">
                                        <h1 class="font-weight-black">{{item.name}}</h1>
                                    </v-card-subtitle>
                                    <v-card-text class="px-0">
                                        <v-col class="py-0"><span>- {{item.records}}</span></v-col>
                                        <v-col class="py-0"><span>- {{item.events}}</span></v-col>
                                        <v-list flat :color="item.color" class="ma-0 pa-0">
                                            <v-list-item-group
                                            >
                                                <v-list-item
                                                v-for="(item, i) in item.includes"
                                                :key="i"
                                                >
                                                    <v-img class="mr-2" max-height="20" max-width="20" :src="item.icon"></v-img>
                                                    <span> {{item.text}}</span>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>
                                    <v-card-actions class="justify-center pb-4">
                                        <v-btn style="text-transform:none" class="accent wDarkerBlue--text font-weight-black" elevation="5" rounded dark>{{item.btn}}</v-btn>
                                    </v-card-actions>
                                </div>
                            </v-card>
                        </v-hover>
                    </div>
                </v-row>
            </v-col>
        </v-row>
        -->
    <!-- Sección about us ¿Porqué existe weChamber? y organizadores destacados-->
    <!--<v-row class="gradient-buttom pa-0 pb-16">
            <v-col>
                <v-row >
                    <v-col class="pa-0" v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="4">
                        <v-img class="border-radius-image"
                            max-height="100%" max-width="100%"
                            src="https://thumbs.dreamstime.com/z/empresario-tomando-caf%C3%A9-en-la-oficina-imagen-vertical-de-un-hermoso-hombre-negocios-cauc%C3%A1sico-con-traje-negro-sentado-y-una-152192487.jpg">
                        </v-img>
                    </v-col>
                    <v-col cols="12" md="2" sm="3" class="pl-0" :class="!$vuetify.breakpoint.smAndDown ? 'alinear-vertical':''">
                        <div
                            v-for="(item, i) in items"
                            :key="i"
                            class="pa-4 mt-2 text-right rounded-r-xl white--text text-no-wrap"
                            :class="[item.color,item.textColor]"
                            :style="$vuetify.breakpoint.smAndDown?'max-width: 15rem;': ''"
                        >
                            <v-row justify="end" class="pr-4">
                                <h1 class="text-right font-weight-black">
                                    +<countTo :startVal='0' :endVal='item.number || currency' :duration='6000'></countTo>
                                </h1>
                            </v-row>
                            <v-row justify="end" class="pr-4 mt-0">
                                <span class="font-weight-bold">{{item.name}}</span>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col align="end" class="pa-16" cols="12" md="6" sm="9">
                        <v-row class="px-md-6">
                            <div class="pb-sm-16 pl-sm-16" style="max-width: 24rem;">
                                <h1 class="font-weight-black wDarkerBlue--text">¿Por qué existe WeChamber?</h1>
                            </div>
                        </v-row>
                        <v-row class="px-md-6">
                            <p class="text-break" style="max-width: 24rem;">Porque estamos absolutamente convencidos de que mientras más dígital es un país, es más productivo y genera mayor riqueza.
                                Existe una gigantesca industria de cursos de capacitación, foros, diplomados, talleres, seminarios y eventos que no contaba
                                con una plataforma que les concentrara a todos, y le permitiese al usuario hacer búsquedas, registrarse, pagar sus
                                entradas y administrar datos.
                            </p>
                        </v-row>
                        <v-row class="px-md-6">
                            <p class="text-break" style="max-width: 24rem;">Tras varios años de publicar la revista Redes Comerciales en la región del Bajío mexicano, dedicada al sector empresarial
                                y sus instituciones, identificamos un inmenso y desatendido mercado. WeChamber es una plataforma que promueve un México
                                más capacitado al conectar la necesidad de conocimiento con la oferta de la capacitación.
                            </p>
                        </v-row>
                        <v-row class="px-md-6" justify="end" style="max-width: 24rem;">
                            <v-btn class="text-capitalize wDarkerBlue justify-center white--text font-weight-black" elevation="5" rounded dark><span class="px-6">Contáctanos</span></v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>-->
    <!-- / Sección about us ¿Porqué existe weChamber? y organizadores destacados-->
  </v-container>
</template>

<script>
import LandingFooter from "@/components/landing/LandingFooter";
import countTo from "vue-count-to";
export default {
  components: {
    countTo,
    LandingFooter,
  },
  data() {
    return {
      visibleUsers: ["user2", "user6", "user8", "user9"],
      notVisibleUsers: ["user1", "user3", "user4", "user5", "user7"],
      //visibleUsers: ["user2", "user6", "user8", "user9", "user11"],
      //notVisibleUsers: ["user1", "user3", "user4", "user5", "user7", "user10"],
      wechamberTools: [
        {
          icon: "mdi-desktop-classic",
          iconColor: "primary",
          title: "Creador de páginas web",
          text: "Crea tus micrositios web con toda la información de tus cursos, eventos o los beneficios de tu asociación.",
        },
        {
          icon: "mdi-credit-card-check",
          iconColor: "primary",
          title: "Pagos en línea",
          text: "Comienza a aceptar pagos en línea en tus cursos, eventos o membresías.",
        },
        {
          icon: "mdi-cash-check", //mdi-file-document-edit
          iconColor: "primary",
          title: "Facturación Automática",
          text: "Te guiamos para que conectes tus sellos fiscales y factures en automático tus cursos o membresías. ",
        },
        {
          icon: "mdi-card-account-details-star",
          iconColor: "primary",
          title: "Constancias de Participación",
          text: "Sube el diseño de tus constancias y deja que WeChamber haga la personalización y el envío.",
        },
        {
          icon: "mdi-finance",
          iconColor: "primary",
          title: "Tablero de Control",
          text: "Monitorea los pagos de tus membresías, cursos y eventos. Visualiza las listas de asistencia y estadísticas de tu organización.",
        },
        {
          icon: "mdi-microsoft-excel",
          iconColor: "primary",
          title: "Reportes",
          text: "Visualiza y descarga reportes de asistencia de cada curso, evento, o membresía.",
        },
      ],
      benefits: [
        {
          src: require("@/assets/images/landing/ico-mejorplataforma.svg"),
          title: "CREA TU SITIO",
          text: "Diseña tu micro sitio web con la información de tu evento y déjalo listo para vender.",
          justify: "end",
        },
        {
          src: require("@/assets/images/landing/ico-acompañamos.svg"),
          title: "VENDE EN LÍNEA",
          text: "Recibe pagos en línea de México y el mundo. Ya está todo integrado.",
          justify: "center",
        },
        {
          src: require("@/assets/images/landing/ico-vendeygestiona.svg"),
          title: "TEN EL CONTROL",
          text: "Monitorea tus ventas, emite cupones, cortesías, facturas, constancias de participación. Mídelo todo.",
          justify: "start",
        },
      ],
      licenses: [
        {
          name: "Free",
          records: "100 registros por evento",
          events: "1 evento al mes",
          src: "",
          btn: "Registrarme gratis",
          color: "wLightGray",
          textColor: "secondary",
          includes: [
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Descargar bases de datos",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "QR, Ligas, Formularios",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Pasarela de pago",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Facturación de eventos",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Encuestas",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Constancias",
            },
          ],
        },
        {
          name: "Básico",
          records: "150 registros por evento",
          events: "4 eventos al mes",
          src: "",
          btn: "Quiero la licencia",
          color: "wLightGray",
          textColor: "secondary",
          includes: [
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Descargar bases de datos",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Cupones y cortesías, QR, Ligas, Formularios",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Pasarela de pago",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Facturación de eventos (Captación en tablero)",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Encuestas",
            },
            {
              icon: require("@/assets/images/general/ico-noincluido.svg"),
              text: "Constancias",
            },
          ],
        },
        {
          name: "Academy",
          records: "Registros ilimitados",
          events: "Eventos ilimitados",
          src: "",
          btn: "Quiero la licencia",
          color: "wLightGray",
          textColor: "secondary",
          includes: [
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Descargar bases de datos",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Cupones y cortesías, QR, Ligas, Formularios",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Pasarela de pago",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Facturación de eventos automatizada",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Encuestas",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Constancias",
            },
          ],
        },
        {
          name: "Congresos",
          records: "Registros ilimitados",
          events: "Micrositio altamente personalizado",
          src: "",
          btn: "Quiero la licencia",
          color: "wLightAccent",
          textColor: "accent",
          includes: [
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Descargar bases de datos",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Cupones y cortesías, QR, Ligas, Formularios",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Pasarela de pago",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Facturación de eventos automatizada",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Encuestas",
            },
            {
              icon: require("@/assets/images/general/ico-incluido.svg"),
              text: "Constancias",
            },
          ],
        },
      ],
      items: [
        {
          name: "Organizaciones",
          number: 100,
          color: "wDarkerBlue",
          textColor: "white--text",
        },
        {
          name: "Asistentes",
          number: 3000,
          color: "accent",
          textColor: "wDarkerBlue--text",
        },
        {
          name: "Eventos",
          number: 200,
          color: "secondary",
          textColor: "wDarkerBlue--text",
        },
      ],
      onboarding: 0,
      qtyLicenses: 0,
    };
  },
  methods: {
    setInitialOpacityVirtualUsers() {
      document.getElementById("user1").style.opacity = "0";
      document.getElementById("user3").style.opacity = "0";
      document.getElementById("user4").style.opacity = "0";
      document.getElementById("user5").style.opacity = "0";
      document.getElementById("user7").style.opacity = "0";
      document.getElementById("user10").style.opacity = "0";
    },
    hideShowVirtualUsers() {
      // La función se seguirá ejecutando aunque se cambie de ruta, asi que es necesario comprobar que el usuario se encuentra en la misma ruta para ejecutar el contenido de la función.
      if (this.$route.fullPath == "/") {
        let visibleUserRandom = Math.floor(
          Math.random() * this.visibleUsers.length
        );
        let notVisibleUserRandom = Math.floor(
          Math.random() * this.notVisibleUsers.length
        );

        let selectedVisibleUser = document.getElementById(
          this.visibleUsers[visibleUserRandom]
        );
        let selectedNotVisibleUser = document.getElementById(
          this.notVisibleUsers[notVisibleUserRandom]
        );
        //let elementSelected = document.getElementById(`user${userRandom}`);

        // Usuarios seleccionados
        let visibleUserName = this.visibleUsers[visibleUserRandom];
        let notVisibleUserName = this.notVisibleUsers[notVisibleUserRandom];

        // Eliminar imágenes seleccionadas de los arreglos
        this.visibleUsers.splice(visibleUserRandom, 1);
        this.notVisibleUsers.splice(notVisibleUserRandom, 1);

        //
        this.visibleUsers.push(notVisibleUserName);
        this.notVisibleUsers.push(visibleUserName);

        selectedVisibleUser.style.opacity = "0";
        selectedNotVisibleUser.style.opacity = "1";
      }
    },
  },
  created() {
    // La función se seguirá ejecutando aunque se cambie de ruta, asi que es necesario comprobar que el usuario se encuentra en la misma ruta para ejecutar el contenido de la función.
    if (this.$route.fullPath == "/") {
      setInterval(this.hideShowVirtualUsers, 4000);
    }
    //this.hideShowVirtualUsers();
  },
  mounted() {
    this.$gtag.screenview({
        app_name: 'App',
        screen_name: 'Home page',
      })  
    this.setInitialOpacityVirtualUsers();
  },
};
</script>
<style scoped>
/* Imagen de dashboard y fotos de usuarios */
#user1 {
  grid-area: user1;
}
#user2 {
  grid-area: user2;
}
#user3 {
  grid-area: user3;
}
#user4 {
  grid-area: user4;
}
#user5 {
  grid-area: user5;
}
#user6 {
  grid-area: user6;
}
#user7 {
  grid-area: user7;
}
#user8 {
  grid-area: user8;
}
#user9 {
  grid-area: user9;
}
#user10 {
  grid-area: user10;
}
#user11 {
  grid-area: user11;
}
#home-dashboard {
  grid-area: dashboard;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "user1 user2 user3 user4 user5"
    "user6 dashboard dashboard dashboard user7"
    "user8 dashboard dashboard dashboard user9";
    /*"user10 dashboard dashboard dashboard user11";*/
  /*grid-gap: 10px;*/
  /*background-color: #2196F3;*/
  /*padding: 10px;*/
  /*grid-auto-rows: 200px;*/
}

.grid-container > img {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 100%;
  /*padding: 20px 0;*/
  /*font-size: 30px;*/
}

img {
  transition: all 2s;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.organizations {
  filter: grayscale(1);
  transition: all 0.6s;
}

.organizations:hover {
  transform: scale(1.2);
}

/* Sección de celulares */
/*
.phone-one {
    position: relative;
    top:400px;
    left: 400px;
    transform: translate(30%, 5%);
}

.phone-two {
    transform: translate(-20%, 20%);
}*/

.phone-one-web {
  position: relative;
  left: 30%;
  top: 5%;
}

.phone-two-web {
  position: relative;
  left: -20%;
  top: 20%;
}

.phone-one-mobile {
  position: relative;
  left: 30%;
  top: 5%;
  margin-bottom: 20vh;
}

.phone-two-mobile {
  position: relative;
  left: -20%;
  top: 20%;
  margin-bottom: 20vh;
}

/*Otras cosas de css */
.gradient-top {
  background: linear-gradient(to top, #f9fafb, white 80%);
}
.gradient-buttom {
  background: linear-gradient(to bottom, #e6f0f2, white 80%);
}
.alinear-vertical {
  transform: translateY(30%);
}
.border-radius-div {
  border-radius: 140px 0px 0px 0px;
}
.border-radius-image {
  border-radius: 140px 0px 140px 0px;
}
.border-radius-sectionBlue {
  border-radius: 0px 0px 125px 0px;
}

/*.card-hover  v-card {
    user-select: none;
    transition: transform .5;
}
.card.hover v-card:hover {
    transform: scale(1.3, 1);
    z-index: 1;
}*/

.cls-1 {
  fill: #1e2a40;
}

/* Hacer que la tarjeta  */
.v-card {
  transition: all 1s;
}

.v-card:not(.on-hover) {
  transform: scale(0.95);
}
/*
#automation-arrow {
    animation: arrow 1s ease-in-out infinite;
}

@keyframes arrow {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(20deg);
    }
}*/
</style>