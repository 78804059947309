<!--Footer de la página-->
<template>
  <v-footer
    class="white--text text-center pa-6 footer-position"
    color="wDarkerBlue"
  >
    <v-row dense align="center">
      <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="2">
        <v-col>
          <v-row justify-md="end" justify="center">
            <a href="" class="white--text">
              <v-img
                src="@/assets/images/general/ico-aws.svg"
                max-height="40"
                max-width="40"
                class="mr-2"
              ></v-img>
            </a>
            <a href="" class="white--text">
              <v-img
                src="@/assets/images/general/ico-pcidss.svg"
                max-height="50"
                max-width="50"
                class="mr-2"
              ></v-img>
            </a>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" md="3">
        <v-col>
          <v-row justify="center">
            <a
              v-for="item in socialMedia"
              :key="item.id"
              :href="item.link"
              target="_blank"
              class="white--text"
            >
              <v-img
                :src="item.img"
                max-height="30"
                max-width="30"
                class="mr-2"
              ></v-img>
            </a>
          </v-row>
          <v-row class="mt-6" justify="center">
            <span class="text-center">contacto@wechamber.mx</span>
          </v-row>
        </v-col>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" md="6">
        <v-col>
          <v-row justify-md="end" justify="center">
            <a href="" class="white--text">
              <v-img
                src="@/assets/images/general/ico-aws.svg"
                max-height="30"
                max-width="30"
                class="mr-2"
              ></v-img>
            </a>
            <a href="" class="white--text">
              <v-img
                src="@/assets/images/general/ico-pcidss.svg"
                max-height="30"
                max-width="30"
                class="mr-2"
              ></v-img>
            </a>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" md="4">
        <v-row justify="center">
          <div class="align-right pt-3 pl-3">
            <v-img
              src="@/assets/images/weChamber/ico-wechamberfooter.svg"
              max-height="30"
              max-width="30"
            ></v-img>
          </div>
          <v-col md="11" sm="8">
            <p class="font-weight-medium">
              &copy; Copyright {{ new Date().getFullYear() }}, Plataforma
              Digital WeChamber. All Rights Reserved.
              <br>
              <v-icon color="white">mdi-map-marker</v-icon>
              2960 Stevens Creek Blvd 2nd
              San Jose,CA 95128
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <v-col class="my-2 py-0">
          <router-link to="/aviso-de-privacidad" class="white--text">
            Política de privacidad
          </router-link>
        </v-col>
        <v-col class="my-2 py-0">
          <router-link to="/terminos-y-condiciones" class="white--text">
            Terminos y condiciones
          </router-link>
        </v-col>
        <v-col class="my-2 py-0">
          <router-link to="/aviso-legal" class="white--text">
            Aviso Legal
          </router-link>
        </v-col>
        <v-col class="my-2 py-0">
          <router-link to="/politica-de-cookies" class="white--text">
            Política de cookies
          </router-link>
        </v-col>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      socialMedia: [
        {
          img: require("@/assets/images/social/ico-ig.svg"),
          link: "https://instagram.com/wechamber?igshid=2otgl2ubntj3",
        },
        {
          img: require("@/assets/images/social/ico-fb.svg"),
          link: "https://www.facebook.com/WeChamber-1878626478915785/",
        },
        {
          img: require("@/assets/images/social/ico-tw.svg"),
          link: "https://www.linkedin.com/company/wechamber/",
        },
        {
          img: require("@/assets/images/social/ico-lnk.svg"),
          link: "https://www.linkedin.com/company/wechamber/",
        },
      ],
    };
  },
};
</script>
<style scoped>
a {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}
a:hover {
  font-weight: bold;
}
</style>