import Vue from 'vue';

export const getBranchMembersPaginated = async ({ commit }, eventsSearchData) => {
  try {
    let url = `/members/filter?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}&name=${eventsSearchData.name}&branch=${eventsSearchData.branch}`

    if(eventsSearchData.role){
      url = url +`&role=${eventsSearchData.role}`  
    }
    if(eventsSearchData.validationStatus){
      url = url +`&validationStatus=${eventsSearchData.validationStatus}`  
    }

    if(eventsSearchData.memberID){
      url = url +`&memberID=${eventsSearchData.memberID}`  
    }

    if(eventsSearchData.isAvailable){
      url = url +`&isAvailable=${eventsSearchData.isAvailable}`  
    }

    if(eventsSearchData.select){
      url = url +`&select=${eventsSearchData.select}`  
    }
    
    let { data } = await Vue.axios({
      method: 'POST',
      url: url,
      data: {
        filters: eventsSearchData.filters || [],
      }
    });

    
    commit('setMembersPaginated', data);

    return { message: "Miembros obtenidos de forma correcta", status: true };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const filterBranchMembersPaginated = async ({ commit }, eventsSearchData) => {
  try {
    console.log(eventsSearchData)
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/members?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}&name=${eventsSearchData.name}&branch=${eventsSearchData.branch}`
    });

    console.log(data)
    commit('setMembersPaginated', data);

    return { message: "Eventos obtenidos de forma correcta", status: true };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const getBranchMembers = async ({ commit }, eventsSearchData) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/members?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}&branch=${eventsSearchData.branch}`
    });

    commit('setMembersPaginated', data);

    return { message: "Eventos obtenidos de forma correcta", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const getMembers = async ({ commit }, eventsSearchData) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/members?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}`
    });

    commit('setMembersPaginated', data);
    return { message: "Eventos obtenidos de forma correcta", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}

//actualizar campos 
export const createMember = async ({ commit }, memberData) => {
  try {
    console.log(memberData.memberInformation)
    let data = await Vue.axios({
      method: 'POST',
      url: '/members',
      data: {
        "branch":memberData.branch,
        "company": memberData.memberInformation.company,
        "externalId": "N/D",
        "email": memberData.memberInformation.email,
        "firstLastname": (memberData.memberInformation.lastName).split(" ")[0]||"N/D",
        "name": memberData.memberInformation.name,
        "profilePicture": "NOT_ASSIGNED",
        "stripeConnectId": "NOT_ASSIGNED",
        "invoiceServiceId": "NOT_ASSIGNED",
        "accountSetup": "NOT_STARTED",
        "invoiceSetup": false,
        "role": "MEMBER",
        "validationStatus": "EXPIRED",
        "hideTutorial": false,
        "memberInformation": memberData.memberInformation
        ,
      }
    });
    console.log(data)

    //commit
    return { message: "Miembro creado exitosamente", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const updateMember = async ({commit},{ userData, id}) => {
  try {
    // memberData.region = memberData.region.text
    console.log(id)
    let { data } = await Vue.axios({
      method: 'PUT',
      url: `/members/head-office/`+id,
      data:{ "user":{
        "company": userData.memberInformation.company,
        "externalId": "N/D",
        "email": userData.memberInformation.email,
        "firstLastname": (userData.memberInformation.lastName).split(" ")[0]||"N/D",
        "name": userData.memberInformation.name,
        "role": "MEMBER",
        "memberInformation":userData.memberInformation,
      }}
    });

    //commit
    return { message: "Miembro actualizado exitosamente", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}

//actualizar campos Final

export const deleteMember = async ({ commit }, id) => {
  try {
    const response = await Vue.axios({
      method: 'DELETE',
      url: `/members/head-office/${id}`
    });

    console.log(response)

    //commit
    return { message: "Miembro eliminado exitosamente", status: true };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const getMemberIndicators = async () => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: '/members/indicators'
    });
    console.log(data)
    return { message: "Indicadores obtenidos exitosamente", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}

export const getChapterIndicators = async ({ commit }, id) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/members/indicators?branch=${id}`
    });
    console.log(data)
    return { message: "Indicadores obtenidos exitosamente", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }
}