<template>
  <v-container>
    <v-row class="d-flex flex-column px-4">
      <v-col class="pa-0" v-for="(item, i) in allAlertsInformation" :key="i">
        <v-alert
          v-if="item.isVisible"
          transition="scroll-x-transition"
          :color="item.alertColor"
          dismissible
          class="white--text"
          max-width="600"
        >
          <v-row class="d-flex align-center">
            <v-col cols="1" :class="!$vuetify.breakpoint.smAndDown || 'pl-0'">
              <v-icon
                v-if="!$vuetify.breakpoint.smAndDown"
                size="30"
                color="white"
              >
                {{ item.icon }}
              </v-icon>
              <v-btn v-else :href="item.link" icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="11"
              class="d-flex flex-column"
              :class="
                !$vuetify.breakpoint.smAndDown || 'flex-column text-center'
              "
            >
              <span
                class="font-weight-bold"
                :class="!$vuetify.breakpoint.smAndDown || 'phone-view'"
              >
                {{ item.message }}
              </span>
              <div
                class="d-flex justify-end"
                v-if="!$vuetify.breakpoint.smAndDown && item.link !== ''"
              >
                <v-btn small outlined color="white" v-if="!item.hideButton" :href="item.link">
                  {{ item.buttonMessage }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    allAlertsInformation: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style scoped>
.phone-view {
  font-size: 12px;
  margin-bottom: 10px;
}
</style>
