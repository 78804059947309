import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication'
import alert from './alert'
import user from './user'
import event from './event'
import affiliation from './affiliation'
import ticket from './ticket'
import attendee from './attendee'
import order from './order'
import notification from './notification'
import loader from './loader'
import purchase from './purchase'
import invoice from './invoice'
import organization from './organization'
import branches from './branches'
import members from './members'
import executives from './executives'
import memberEvents from './memberEvents'
import memberAffiliates from './memberAffiliates'
import member from './member'
import membershipUsers from './membershipUsers'
import microsite from './microsite'
import registrationFields from './registrationFields'
import publications from './publications'
import debts from './debts'
import membershipProfile from './membership-profile'
import benefits from './benefits'



import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

// Guardar en local storage
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user']
})

export default new Vuex.Store({
  modules: {
    authentication,
    user,
    alert,
    event,
    affiliation,
    ticket,
    attendee,
    order,
    notification,
    loader,
    purchase,
    invoice,
    organization,
    branches,
    members,
    executives,
    memberEvents,
    memberAffiliates,
    member,
    membershipUsers,
    microsite,
    registrationFields,
    publications,
    debts,
    membershipProfile,
    benefits
  },
  plugins: [vuexLocal.plugin]
});
