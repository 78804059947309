<template>
  <v-avatar size="120" class="root" rounded="0">
    <v-img
      lazy-src="@/assets/images/weChamber/wechamber-blue-logo-placeholder.png"
      :src="currentUrl"
      width="auto"
      contain
      style="border-radius: 50%;"
    />
    <div class="d-flex flex-column btns-container">
      <!-- <v-btn
        fab
        color="primary"
        small
        class="mb-2"
        v-show="tempUrl"
        @click="handleSaveImage"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn> -->
      <v-btn
        fab
        color="accent"
        small
        @click="handleFileClick"
        :disabled="!canEdit"
      >
        <v-icon small color="white">fas fa-pencil-alt</v-icon>
      </v-btn>
    </div>
    <input
      type="file"
      :accept="acceptFiles.join(',')"
      hidden
      ref="editImg"
      @change="handleEditImage"
    />
  </v-avatar>
</template>
<script>
export default {
  props: {
    imgUrl: {
      type: [String, File],
      default: ''
    },
    acceptFiles: {
      type: Array,
      default: () => [
        '.png',
        '.jpg',
        '.jpeg'
      ]
    },
    canEdit:{
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    file: null,
    tempUrl: '',
  }),
  methods: {
    handleFileClick() {
      this.$refs.editImg.click();
    },
    handleEditImage(e) {
      const [file] = e.target.files;
      if (file) {
        this.tempUrl = URL.createObjectURL(file);
        this.file = file;
      }
      this.$emit('save', this.file, this.resetState);
    },
    resetState() {
      this.file = '';
      this.tempUrl = '';
    },

  },
  computed: {
    currentUrl() {
      if (typeof this.imgUrl == 'object') {
        return URL.createObjectURL(this.imgUrl)
      }
      return this.tempUrl ? this.tempUrl : this.imgUrl;
    }
  }
};
</script>
<style scoped>
.root {
  overflow: visible;
}
.btns-container {
  position: absolute;
  right: -45px;
  bottom: 0;
}
</style>
