<template>
  <v-container fluid >
    <!-- Navegación izquierda -->
    <v-navigation-drawer
      v-model="sidenav"
      app
      fixed
      width="235"
    >
      <v-list nav dense>
        <v-list-item class="d-flex justify-center mt-14">
          <v-img v-if="user.profilePicture!=''" :src="user.profilePicture" max-width="150"></v-img>
          <v-img
            v-else
            class=""
            src="@/assets/images/weChamber/logo-WC-microsite.svg"
            max-width="150"
          ></v-img>
        </v-list-item>
        <!--<v-list-item v-if="$vuetify.breakpoint.mdAndDown">
        </v-list-item>-->
      </v-list>
      <v-list nav rounded dense>
        <v-list-item-group>
          <v-list-item
            :to="{ name: entityType=='Event'?'mis-eventos': entityType == 'Chapter' ? 'chapters' : 'myMembers' }"
            class="px-6 px-md-2"
            active-class="white--text wDarkerBlue"
          >
            <v-list-item-icon>
              <v-icon>fas fa-chevron-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Volver al inicio</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="({ name, icon, title }) in navigationsComputed"
            :key="name"
            :to="{ name, params: { eventId: $route.params.eventId }}"
            :disabled="title=='Panel Local'||title=='Mis Eventos'"
            class="px-6 px-md-2"
            active-class="white--text wDarkerBlue"
          >
            <v-list-item-icon>
              <v-icon>{{icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{title}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- / Navegación izquierda -->
    <!-- Menú derecho -->
    <v-row>
      <v-col
      cols="12"
      class="d-flex justify-end my-4"
    >
      <!--<v-list-item>
        <p>Evento perrón dadsadasd das  sad  dd asd asddsa </p>
      </v-list-item>-->
      <!--<v-list-item-icon v-if="$vuetify.breakpoint.mdAndDown">
        <v-img
          max-height="40"
          max-width="40"
          src="@/assets/images/weChamber/logo-WC.png"
        ></v-img>
      </v-list-item-icon>-->
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="mx-2"
        color="primary"
        icon
        large
        @click="sidenav = true"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <account-menu @logOut="closeLogOutDialog"></account-menu>
    </v-col>
    </v-row>
    <PersonalizedDialog
    :dialog="logOutDialog"
    @save="_logout"
    @discard="logOutDialog=false">
      <template v-slot:alertContent>
        <p class="title text-center">
              ¿Está seguro que desea cerrar sesión?
                </p>
      </template>
    </PersonalizedDialog>
    <!-- / Menú derecho -->
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import OrganizerBalance from "./DashboardNavbar/OrganizerBalance";
import AccountMenu from './DashboardNavbar/AccountMenu.vue';
import PersonalizedDialog from '../shared/PersonalizedDialog.vue';
export default {
  props: ['entityType' ,'navigations'],
  components: {
    OrganizerBalance,
    PersonalizedDialog,
    AccountMenu
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState('event', ['eventSelected']),
    navigationsComputed(){
      if(this.entityType=='Event' && this.eventSelected && this.eventSelected.invitationalEvent){
        return this.navigations.filter(navigation=>navigation.title!='Accesos y ventas');
      }else{
        return this.navigations;
      }
    }
  },
  data() {
    return {
      sidenav: false,
      logOutDialog: false
    }
  },
  methods: {
    ...mapMutations('user', ['logout']),
    _logout(){
      this.logout();
      this.$router.push('/login');
    },
    closeLogOutDialog(){
      this.logOutDialog=true
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.sidenav = true;
    }
  }
}
</script>
<style scoped>
.rounded-container {
  border-radius: 6rem !important;
}
</style>