import Vue from 'vue';
import VueAxios from 'vue-axios';
import member from '.';
import Endpoints from '../../share/Endpoints';

/**
 * función que permita recolectar la información
de los datos del usuario logeado 
*/
export const fetchMemberStatus = async ({ commit }) => {
  try {
    let indicators = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberIndicators,
    });
    commit('setStatus', indicators)
    return { status: true, info: indicators.data.dashboardIndicators }
  } catch (err) {
    return err;
  }
}

export const fetchMemberCredentials = async ({ commit }) => {
  try {
    let credential = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberCredentials,
    });
    commit('setCredentials', credential.data)
    return { status: true, credential: credential.data.credentialInformation }
  } catch (err) {
    return err
  }
}

export const fetchMemberItems = async ({ commit }, branchId) => {
  try {
    let membItems = await Vue.axios({
      method: 'GET',
      url: `${Endpoints.memberItems}/${branchId}`,
    });
    commit('setItems', membItems)
    return membItems
  } catch (err) {
    return err
  }
}


export const deleteMemberItems = ({commit})=>{
  try {
    commit('delItems')
  } catch (error) {
    return error
  }
}

export const memberInformation = async ({ commit }) => {
  try {
    let info = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberInformation,
    });
    return info.data
  } catch (error) {
    return error
  }
}

export const allMembers = async ({ commit }) => {
  try {
    let members = await Vue.axios({
      method: 'GET',
      url: '/members?page=1&limit=366214'
    })
    return members.data
  } catch (error) {
    return error
  }
}


export const updatePassword = async ({ commit }, password) =>{
  try {
    let passwordUpdated = await Vue.axios({
      method: 'POST',
      url: '/authentication/password-reset',
      data: password
    })
    return {status: true, newPassword: passwordUpdated}
  } catch (error) {
    return error
  }
}

export const updateMember = async ({ commit }, updatedData) => {

  try {
    let updatemember = await Vue.axios({
      method: 'PUT',
      url: '/members',
      data: updatedData
    })
    return {status: true, newInfo:updatemember}
  } catch (error) {
    return error

  }
}

export const updateMembershipUSerInfo = async({commit}, upData)=>{
  try {
    let updateembershipUSer=await Vue.axios({
      method: 'PUT',
      url: '/membership-users',
      data: {
        "membershipUser": {
        "name": upData.memberInformation.name,
        "lastName": upData.memberInformation.lastName,
        "email": upData.memberInformation.email,
        "memberInformation": upData.memberInformation
        }
      }
    })
    return{status: true , newInfo:updateembershipUSer}
  } catch (error) {
    return error
  }
}

export const fetchMemberDebt = async ({ commit }) => {
  try {
    let indicators = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberIndicators,
    });
    commit('setStatus', indicators)
    return { status: true, info: indicators.data.dashboardIndicators }
  } catch (err) {
    return err;
  }
}