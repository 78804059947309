export function setStatus(state,status){
  state.info=status;
}

export function setCredentials(state, credential){
  state.credential=credential;
}

export function setItems(state, items){
  state.items=items;
}

export function delItems(state){
  state.credential={}
  state.items={}
  state.info={}
}