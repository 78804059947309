import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const getEvents = async ( {commit}, organizationId ) => {
  try {
    let events = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberEvents+`/${organizationId}`
    });
    commit('setEvents', events);
    return {status: true, events: events};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const getEventsById = async ( {commit}, orgId ) => {
  try {
    let event = await Vue.axios({
      method: 'GET',
      url: Endpoints.memberEvents+'/'+orgId
    });
    commit('setEvents', events);
    return {status: true, events: events};
  }
  catch (error) {
    return {status: false, message: error}
  }
}