<template>
  <v-container fluid class="pa-0">
    <!-- Navegación izquierda -->
    <v-navigation-drawer
      v-model="sidenav"
      app
      fixed
      mobile-breakpoint="960"
      class="step-2"
    >
      <v-list nav dense>
        <v-list-item class="d-flex justify-center py-5 mt-14">
          <v-img
            v-if="
              user.profilePicture != '' && user.profilePicture != 'NOT_ASSIGNED'
            "
            :src="user.profilePicture"
            max-width="150"
          ></v-img>
          <v-img
            v-else
            src="@/assets/images/weChamber/logo-WC-microsite.svg"
            max-width="100"
            contain
          ></v-img>
        </v-list-item>
        <!--<v-list-item v-if="$vuetify.breakpoint.mdAndDown">
          <v-img src="@/assets/images/weChamber/logo-WC.png"></v-img>
        </v-list-item>-->
      </v-list>
      <v-list nav rounded dense>
        <v-list-item-group v-model="item">
          <template v-if="user.role === 'ORGANIZATION'">
            <div v-for="(item, i) in permissionsNav" :key="i" class="mx-4">
              <v-list-item
                v-if="!item.disabled"
                :to="item.link"
                class="ml-4"
                active-class="white--text wDarkerBlue "
              >
                <v-list-item-icon class="px-2 d-flex justify-center">
                  <v-icon>{{ item.icon }}</v-icon>
                  <!-- <v-badge
                    v-show="item.badge"
                    color="transparent"
                    overlap
                    dark
                    offset-y="20"
                  >
                    <template v-slot:badge>
                      <v-icon color="red lighten-2" size="20">
                        mdi-star mdi-spin
                      </v-icon>
                    </template>
                  </v-badge> -->
                </v-list-item-icon>
                <v-list-item-title
                  >{{ item.title }}
                  <p
                    v-if="item.disabled"
                    class="caption secondary--text font-weight-bold mb-0"
                  >
                    Próximamente
                  </p>
                </v-list-item-title>
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                :offset-x="$vuetify.breakpoint.mdAndUp?'70':'40'"
                offset-y="8"
              >
                <template v-slot:badge>
                  <p class="caption secondary--text font-weight-bold mb-0">Pronto...</p>
                </template>  
              </v-badge>-->
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                overlap
                dark
                offset-y="20"
              >
                <template v-slot:badge>
                  <p class="secondary--text">Próximamente</p>
                </template>  
              </v-badge>-->
              </v-list-item>
            </div>
          </template>

          <div v-else>
            <div v-for="(item, i) in permissionsNav" :key="i">
              <v-list-item
                v-if="!item.disabled"
                :to="item.link"
                class="d-flex justify-center py-0"
                active-class="white--text wDarkerBlue font-weight-800"
              >
                <v-list-item-icon class="d-flex justify-center mx-4 py-0">
                  <v-icon>{{ item.icon }}</v-icon>
                  <!-- <v-badge
                    v-show="item.badge"
                    color="transparent"
                    overlap
                    dark
                    offset-y="20"
                  >
                    <template v-slot:badge>
                      <v-icon color="red lighten-2" size="20">
                        mdi-star mdi-spin
                      </v-icon>
                    </template>
                  </v-badge> -->
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }} </v-list-item-title>
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                :offset-x="$vuetify.breakpoint.mdAndUp?'70':'40'"
                offset-y="8"
              >
                <template v-slot:badge>
                  <p class="caption secondary--text font-weight-bold mb-0">Pronto...</p>
                </template>  
              </v-badge>-->
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                overlap
                dark
                offset-y="20"
              >
                <template v-slot:badge>
                  <p class="secondary--text">Próximamente</p>
                </template>  
              </v-badge>-->
              </v-list-item>
            </div>
          </div>

          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            to="/escanear-qr"
            class="d-flex justify-center"
            active-class="white--text wDarkerBlue"
          >
            <v-list-item-icon class="mx-4">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Escanear código QR </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            class="px-6 px-md-2 red darken-4"
            @click="logOutDialog = true"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text font-weight-bold"
              >Cerrar sesión</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- / Navegación izquierda -->
    <!-- Menú derecho -->
    <v-col cols="12" class="d-flex justify-end mb-5">
      <!--<v-list-item>
        <p>Evento perrón dadsadasd das  sad  dd asd asddsa </p>
      </v-list-item>-->
      <!--<v-list-item-icon v-if="$vuetify.breakpoint.mdAndDown">
        <v-img
          max-height="40"
          max-width="40"
          src="@/assets/images/weChamber/logo-WC.png"
        ></v-img>
      </v-list-item-icon>-->
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="mx-2"
        color="primary"
        icon
        large
        @click="sidenav = true"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>

      <!-- <OrganizerBalance :classes="['rounded-container']" :user="user" /> -->

      <div>
        <AccountMenu @logOut="closeLogOutDialog"></AccountMenu>
        <!--  -->
      </div>
    </v-col>
    <PersonalizedDialog
      :dialog="logOutDialog"
      @save="_logout"
      @discard="logOutDialog = false"
    >
      <template v-slot:alertContent>
        <p class="title text-center">¿Está seguro que desea cerrar sesión?</p>
      </template>
    </PersonalizedDialog>
    <!-- / Menú derecho -->
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ActionButton from "@/components/shared/ActionButton";
import OrganizerBalance from "./OrganizerBalance";
import AccountMenu from "./AccountMenu.vue";
import Endpoints from "@/share/Endpoints";
import PersonalizedDialog from "../../../components/shared/PersonalizedDialog";
export default {
  components: {
    ActionButton,
    OrganizerBalance,
    PersonalizedDialog,
    AccountMenu,
  },
  data() {
    return {
      sidenav: false,
      bottomNav: 0,
      item: 1,
      logOutDialog: false,
      menuContentElevation: 0,
      drawer: false,
      tabs: [
        { title: "Editar mis datos", icon: "mdi-account-edit-outline" },
        { title: "Cerrar sesión", icon: "mdi-logout-variant" },
      ],
      navigations: [],
      roleNavigations: [
        {
          icon: "mdi-view-dashboard-outline",
          title: "Panel de inicio",
          link: "/panel-inicio",
          disabled: true,
        },
        {
          icon: "mdi-calendar-outline",
          title: "Mis eventos",
          link: "/mis-eventos",
          disabled: true,
        },
        {
          icon: "mdi-badge-account-horizontal-outline",
          title: "Mis Miembros",
          link: "/miembros",
          disabled: true,
        },
        {
          icon: "mdi-store-outline",
          title: "Mis Capítulos",
          link: "/capitulos",
          disabled: true,
        },
        {
          icon: "mdi-wallet-outline",
          title: "Mis Ingresos", //dispersiones
          link: "/dispersiones",
          disabled: true,
        },
        {
          icon: "mdi-radio",
          title: "Mi Comunidad", //Community
          link: "/mi-comunidad",
          disabled: false,
        },
        {
          icon: "mdi-chart-bar",
          title: "Data Center",
          link: "/graphs-ampi",
          disabled: false,
        },
        {
          icon: "mdi-hand-coin-outline",
          title: "Mis Beneficios",
          link: "/mis-beneficios",
          disabled: false,
        },
        {
          icon: "mdi-alpha-s-circle-outline",
          title: "Stripe",
          link: "/embedded",
          disabled: false,
        },
         {
           icon: "mdi-account-group",
           title: "Subgrupos",
          link: "/subgrupos",
          disabled: false,
         },
        // {
        //   icon: "mdi-face-man-shimmer-outline",
        //   title: "Mis membresías",
        //   link: "/mis-membresias",
        //   disabled: true,
        // },
        // {
        //   icon: "mdi-comment-question-outline",
        //   title: "Mesa de ayuda",
        //   link: "/mesa-ayuda",
        //   disabled: true,
        // },
        // {
        //   icon: "mdi-credit-card",
        //   title: "Mis compras",
        //   link: "/facturacion",
        //   disabled: true,
        // },
        // {
        //   icon: "mdi-chart-timeline-variant",
        //   title: "Dispersiones",
        //   link: "/dispersiones",
        //   disabled: true,
        // },
        // {
        //   icon: "mdi-account",
        //   title: "Mi cuenta",
        //   link: "/mi-cuenta",
        //   badge: true,
        //   disabled: true,
        // },
        // {
        //   icon: "admin_panel_settings",
        //   title: "Permisos",
        //   link: "/permisos",
        //   disabled: true,
        // },
        // {
        //   icon: "store",
        //   title: "Mis Capítulos",
        //   link: "/capitulos",
        //   disabled: true,
        // },
        // {
        //   icon: "badge",
        //   title: "Mis Miembros",
        //   link: "/miembros",
        //   disabled: true,
        // },
      ],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    permissionsNav() {
      let nav = this.roleNavigations;
      const findIndexOfRoutes = (linkArray, link) => {
          return [...linkArray].findIndex(element => element.link === link);
      };

      if (this.user.permissions.Membership) {
        let {create, read, update} = this.user.permissions.Membership;
        if ([create, read, update].some(element => element === false)) {
          nav[findIndexOfRoutes(nav, '/mis-beneficios')].disabled = true
        }
      }

      // ocultar data center y stripe de organizaciones diferentes a ampi
      if ((this.user._id != "6297b0fec7bf8b6f826313be")) {
        nav[findIndexOfRoutes(nav, '/embedded')].disabled = true;
        nav[findIndexOfRoutes(nav, '/graphs-ampi')].disabled = true;
      }

      if (this.user.permissions.Panel && this.user.permissions.Panel.read) {
        nav[0].disabled = false;
      }
      if (this.user.permissions.Event && this.user.permissions.Event.read) {
        nav[1].disabled = false;
      }
      if ([ "HEAD_OFFICE", "BRANCH", "ORGANIZATION_USER" ].includes(this.user.role)) {
        const { Members: { read: memberRead = false }, Chapters: { read: chapterRead = false } } = this.user?.permissions || {};

        if ( memberRead ) {
          nav[findIndexOfRoutes(nav, '/miembros')].disabled = false;
        }
        if ( chapterRead ) {
          nav[findIndexOfRoutes(nav, '/capitulos')].disabled = false;
        }
      }
      if (
        this.user.permissions.Dispersions &&
        this.user.permissions.Dispersions.read
      ) {
        nav[findIndexOfRoutes(nav, '/dispersiones')].disabled = false;
      }

      if (this.user.hierarchyType && this.user.hierarchyType == "ONE_LEVEL") {
        nav[findIndexOfRoutes(nav, '/capitulos')].disabled = true;
      }

      // if (
      //   this.user.permissions.Membership &&
      //   this.user.permissions.Membership.read
      // ) {
      //   nav[5].disabled = false;
      // }
      return nav;
      // }
      // if (
      //   this.user.permissions.Membership &&
      //   this.user.permissions.Membership.read
      // ) {
      //   nav[2].disabled = false;
      // }
      // if (this.user.permissions.Help && this.user.permissions.Help.read) {
      //   nav[3].disabled = false;
      // }
      // if (this.user.permissions.Payouts && this.user.permissions.Payouts.read) {
      //   nav[4].disabled = false;
      // }
      // if (this.user.permissions.Dispersions && this.user.permissions.Dispersions.read) {
      //   nav[5].disabled = false;
      // }
      // if (this.user.permissions.Account && this.user.permissions.Account.read) {
      //   nav[6].disabled = false;
      // }
      // if (
      //   this.user.permissions.Permission &&
      //   this.user.permissions.Permission.read &&
      //   (
      //     this.user.role === "HEAD_OFFICE" ||
      //     this.user.role === "BRANCH" ||
      //     this.user.role === "ORGANIZATION_USER"
      //   )
      // ) {
      //   nav[7].disabled = false;
      // }
      // if (
      //   this.user.role === "HEAD_OFFICE" ||
      //   this.user.role === "BRANCH" ||
      //   this.user.role === "ORGANIZATION_USER"
      // ) {
      //   if (
      //     this.user.permissions.Chapters &&
      //     this.user.permissions.Chapters.read
      //   ) {
      //     nav[8].disabled = false;
      //   }
      //   if (
      //     this.user.permissions.Members &&
      //     this.user.permissions.Members.read
      //   ) {
      //     nav[9].disabled = false;
      //   }
      // }
    },
  },
  methods: {
    ...mapMutations("user", ["logout"]),
    ...mapMutations("alert", ["showAlert"]),
    _logout() {
      this.logout();
      this.$router.push("/login");
    },
    async getMethodPaymentActive() {
      const response = await this.axios.get(
        Endpoints.excecuteGetMethodPayment.replace(/:userID/, this.user._id)
      );
      const data =
        response.data.methods[
          response.data.methods.findIndex(
            (item) => item.id == "bank_transfer_payments"
          )
        ].active;
      this.showAlert(data);
    },
    closeLogOutDialog() {
      this.logOutDialog = true;
    },

    formatPermission(permission) {
      return `${permission.action}-${permission.subject}`;
    },
    closeMenu() {
      this.menuExpand = false;
    },

    getNav(permission) {
      let validation = {
        "create-Event": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
        ],
        "read-Membership": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
        ],
        "read-Event": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
        ],
        "read-Membership": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
        ],
        "read-Permission": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Payouts": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Purchase": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Transaction": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
      };

      return validation[permission];
    },
  },
  async mounted() {
    // Validar si el usuario ya conectó su cuenta de stripe y de facturación para quitar la ayuda con las secciones que falta modificar
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.sidenav = true;
    }
    await this.getMethodPaymentActive();
  },
};
</script>
<style scoped>
.rounded-container {
  border-radius: 6rem !important;
}
</style>
