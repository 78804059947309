<template>
  <div>
    <div
      :class="`
        d-flex
        justify-around
        align-center
        px-md-5
        mx-md-5
        inner-spacing
        ${classes.join(' ')}
    `"
    >
      <!-- <v-menu open-on-hover bottom offset-y nudge-left="200" max-width="400">
        <template #activator="{ on, attrs }">
          <v-icon color="black" v-on="on" v-bind="attrs">
            fas fa-question-circle
          </v-icon>
        </template>
        <v-list>
          <v-list-item>
            <span class="text-justify">
              Tu saldo en registros se refiere a la cantidad de personas que
              puedes inscribir en cursos y eventos o membresías&nbsp;
              <button
                class="font-weight-bold cursor"
                @click="showDialog = true"
              >
                ver más...
              </button>
            </span>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <!-- <v-row :justify="$vuetify.breakpoint.smAndDown?'center':'left'">
        <v-col :cols="$vuetify.breakpoint.smAndDown? '6': 'auto'"  class="pa-0">
        <div :class="$vuetify.breakpoint.smAndDown?'mx-0':'mx-3'" class="py-0 text-caption font-weight-bold blue-grey--text d-flex flex-column align-center">
            <span class="">Por Asignar</span>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="5"
              :value="(user.assignableItems / user.totalItems) * 100"
              color="blue-grey"
            >
              {{ user.assignableItems }}
            </v-progress-circular>
        </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown? '6': 'auto'"  class="pa-0">  
        <div class="py-0 mx-3 text-caption font-weight-bold purple--text d-flex flex-column align-center">
            <span class="">Asignados</span>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="5"
              :value="(user.assignedItems / user.totalItems) * 100"
              color="purple"
            >
              {{ user.assignedItems }}
            </v-progress-circular>
        </div>
      </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown? '6': 'auto'"  class="pa-0">
        <div class="py-0 mx-3 text-caption font-weight-bold green--text d-flex flex-column align-center">
            <span class="">Vendidos</span>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="5"
              :value="(user.soldItems / user.totalItems) * 100"
              color="green"
            >
              {{ user.soldItems }}
            </v-progress-circular>
        </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown? '6': 'auto'"  class="pa-0">
        <div  class="py-0 mx-3 text-caption font-weight-bold amber--text d-flex flex-column align-center">
            <span class="">Pendientes</span>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="5"
              :value="(user.orderPaymentPendingItems / user.totalItems) * 100"
              color="amber"
            >
              {{ user.orderPaymentPendingItems }}
            </v-progress-circular>
        </div>
        </v-col>
        <v-col cols="auto" class="pa-0">
        <div class="py-0 mx-5 text-body-1 font-weight-bold d-flex flex-column align-center">
            <span class="">Total</span>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="0"
              :value="100"
              color="black"
            >
              {{ user.totalItems }}
            </v-progress-circular>
        </div>
        </v-col>
      </v-row> -->
      <!--<span class="font-weight-bold">{{availableItems}} registros</span>-->
      <!-- <v-btn
        class="font-weight-bold black--text"
        color="accent"
        rounded
        @click="showBalanceDialog = true"
        :disabled="!user.permissions.Purchase || !user.permissions.Purchase.create"
      >
        {{ $vuetify.breakpoint.mdAndUp ? "Comprar saldo" : "Comprar" }}
      </v-btn> -->
      <!--<action-button @click="showBalanceDialog = true">
        Comprar saldo
      </action-button>-->
      <!-- <v-dialog
        v-model="showDialog"
        width="450"
        @click:outside="showDialog = false"
      >
        <v-card class="pa-10">
          <v-card-text class="text-justify">
            <p>
              Es irrelevante el número de micrositios que generes para eventos o
              membresías, tu saldo lo puedes utilizar conforme lo vayas
              necesitando y puedes recargar en cualquier momento. Se descuenta
              un registro cada vez que alguien se inscribe en uno de tus eventos
              o se afilia a tu organización.
            </p>
            <p>
              Por ejemplo: Si compro un saldo de 500 registros, puedo consumirlo
              todo en un evento para 500 personas o en 10 talleres para 50
              personas, no importa, solo recuerda que tienes un año para
              consumir tu saldo a partir del momento en que adquieras cada
              paquete. Los primeros 35 van por nuestra cuenta.
            </p>
            <p>
              ¿Qué incluye cada "registro"? Hemos homologado todo a un solo tipo
              de registro que puede ir desde el registro de una persona a un
              evento gratuito, hasta aquellos registros que usan pago en línea,
              facturación automática y envío de diploma de participación. Cada
              vez que alguien se registre en algo, se descuenta una unidad de tu
              saldo.
            </p>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <action-button @click="showDialog = false">
              Entendido, ir al tablero
            </action-button>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
    <BalancePlans
      :open="showBalanceDialog"
      @close="showBalanceDialog = false"
      @open="showBalanceDialog = true"
    />
  </div>
</template>
<script>
import ActionButton from "@/components/shared/ActionButton";
import BalancePlans from "./BalancePlans";

export default {
  components: {
    ActionButton,
    BalancePlans,
  },
  props: ["classes", "user"],
  data: () => ({
    showDialog: false,
    showBalanceDialog: false,
  }),
};
</script>
<style scoped>
.bg-hay {
  background-color: #fff7e5;
}
.caption {
  line-height: 100% !important;
}
/*.inner-spacing > * {
  margin: 0 10px;
}*/

@media (max-width: 960px) {
  .inner-spacing > * {
    margin: 0 5px;
  }
}

@media (min-width: 961px) {
  .inner-spacing > * {
    margin: 0 10px;
  }
}
</style>