import Vue from 'vue';

export const createOrganizationUser = async ({ commit }, userData) => {
  try {
    let { data: { user, token } } = await Vue.axios({
      method: 'POST',
      url: '/organization-users',
      data: {
        user: {
          email: userData.email,
          firstLastname: userData.firstLastname,
          name: userData.name,
          password: userData.password,
          position: userData.position,
          permissionsType: userData.permissionsType,
          role: "ORGANIZATION_USER"
        }
      },
    });

    return { status: true, message: "Usuario registrado correctamente. Por favor revisa tu correo para validar tu cuenta.", user: user };
  } catch (err) {
    return { status: false, message: "Ha ocurrido un problema al registrar el usuario", user: err };
  }
}

export const getOrganizationUsers = async ({ commit }) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: '/organization-users'
    });

    commit('setOrganizationUser', data.organizationUsers);
    return { status: 200, message: "Información consultada correctamente", user: data.organizationUsers };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al obtener los usuarios", user: error };
  }
}

export const getOrganizationUserByName = async ({ commit }, name) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/organization-users?name=${name}`
    });

    //commit('setOrganizationUser', data.organizationUsers);
    return { status: 200, message: "Información consultada correctamente", user: data.organizationUsers };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al obtener los usuarios", user: error };
  }
}

export const updateOrganizationUser = async ({ commit }, userData) => {
  try {
    console.log(userData)
    let response = await Vue.axios({
      method: 'PUT',
      url: `/organization-users/${userData._id}`,
      data: {
        user: userData
      },
    });
    return { status: 200, message: "El usuario se ha actualizado exitosamente" }
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al actualizar el usuario", user: error };
  }
}

export const deleteOrganizationUser = async ({ commit }, id) => {
  try {
    console.log(id)
    await Vue.axios({
      method: 'DELETE',
      url: `/organization-users/${id}`
    })
    return { status: 200, message: "Usuario eliminado correctamente" };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al eliminar el usuario", user: error };
  }
}

