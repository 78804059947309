import Vue from 'vue';

export const getRegistrationFields = async ({ commit }, id) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: '/registrationFields/form/' + id,
    });
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const addRegistrationField = async ({ commit }, payload) => {
  try {
    let {data} = await Vue.axios({
      method: 'POST',
      url: '/registrationFields/form',
      data: {
        payload: payload
      }
    });
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const updateRegistrationField = async ({ commit }, {id, payload}) => {
  try {
    let {data} = await Vue.axios({
      method: 'PUT',
      url: '/registrationFields/form/' + id,
      data: {
        payload: payload
      },
    });
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

// registration docs in branches
export const getBranchesDocs = async () => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: '/registrationFields/form/branches/docs'
    });

    return {status: true, data}
  } catch (error) {
    return {message: error, status: false}
  }
}

export const postBranchesDocs = async ({commit}, payload) => {
  try {
    let {data} = await Vue.axios({
      method: 'POST',
      url: '/registrationFields/form/branches/docs',
      data: {
        payload: payload
      }
    });

    return {status: true, data}
  } catch (error) {
    return {message: error, status: false}
  }
}

export const putBranchesDocs = async ({commit}, payload) => {
  try {
    let {data} = await Vue.axios({
      method: 'PUT',
      url: '/registrationFields/form/branches/docs',
      data: {
        payload: payload
      }
    });

    return {status: true, data}
  } catch (error) {
    return {message: error, status: false}
  }
}