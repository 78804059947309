import Vue from 'vue';

export const fetchEventAttendees = async ({ commit }, attendeeSearchData) => {
    try {
        let { data } = await Vue.axios({
            method: 'GET',
            url: `/attendees/${attendeeSearchData.eventId}?page=${attendeeSearchData.page}&limit=${attendeeSearchData.limit}`
        });
        commit('setEventAttendees', data);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const checkIn = async ({ commit }, payload) => {
    try {
        // also obtain the error message if any
        let { data } = await Vue.axios({
            method: 'POST',
            url: `/attendees/attendee-management/qr-check-in`,
            data: {
                attendee: {
                    id: payload.id,
                    event: payload.eventId
                },
                invitational: payload.invitational
            }
        });
        commit('setEventAttendees', data);
        return data;
    } catch (error) {
        console.log(error);
        return {message: error.response.data.error.message, status: false} ;
    }
}
