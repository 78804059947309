import { uuid } from 'vue-uuid';
import Vue from 'vue';

export const imageHelper = {
    resizeImg(file = null, maxWidth = 1800, maxHeight = 800) {
        return new Promise(resolve => {
            if (file === null)
                return null;

            const reader = new FileReader();
            const mime = file.type;
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let width = 0;
            let height = 0;
            let img = document.createElement("img");
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");

            // Set the image once loaded into file reader
            reader.onloadend = (e) => {
                img.src = e.target.result;
                img.onload = () => {
                    width = img.width;
                    height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    let dataurl = canvas.toDataURL(mime);
                    let image_blob = (() => {

                        let binary = atob(dataurl.split(',')[1]);
                        let array = [];
                        for (let i = 0; i < binary.length; i++) {
                            array.push(binary.charCodeAt(i));
                        }
                        let blob = new Blob([new Uint8Array(array)], { type: mime });
                        let fileImg = new File([blob], `${uuid.v1()}.${file.name.split('.').pop()}`, { type: mime });
                        fileImg.originalname = file.name;
                        resolve(fileImg);
                    })();
                };
            }
            reader.readAsDataURL(file);
        });
    },
    resizeImgs(files = null) {
        return new Promise(async (resolve) => {
            if (files === null)
                return null;

            let images = [];
            let image = null

            for (let i = 0; i < files.length; i++) {
                image = await this.resizeImg(files[i].file);
                images.push(image);
            }
            resolve(images);
        });
    },
    async saveImage(file) {
        const formData = new FormData();
        formData.append("file", file);
        let response = await Vue.axios(
            {
                method: 'POST',
                url: `${process.env.VUE_APP_API_ENDPOINT}/utils/uploadFileS3`,
                data: formData,
            }
        );
        return response.data.Location;
    },
    async saveSponsorImage(file) {
        const formData = new FormData();
        formData.append("file", file);
        let response = await Vue.axios(
            {
                method: 'POST',
                url: `${process.env.VUE_APP_API_ENDPOINT}/utils/uploadSponsorFileS3`,
                data: formData,
            }
        );
        return response.data.Location;
    }
}