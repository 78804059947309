export default {
  affiliation: {},
  affiliationSelected: {},
  affiliationMicrositeData: {},
  organizationAffiliations: [],
  organizationAffiliationsPaginated: {
    affiliations: [],
    totalDocs: null,
    limit: 8,
    totalPages: null,
    page: 1,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: null
  },
  affiliationFaqs: []
}