<template>
  <v-btn
    :class="btnClass"
    :color="colorVariant"
    :block="smBreakpoint"
    rounded
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click', $event)"
    :elevation="elevation"
  >
        <v-icon :color="textColor" v-show="iconLeft">
            {{ icon }}
        </v-icon>
        <span :class="`font-weight-bold ${textColor}--text px-3`">
          <slot>Click</slot>
        </span>
    </v-btn>
</template>
<script>
export default {
  props: {
    btnClass: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: 'black'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: String,
      default: null
    }
  },
  computed: {
    colorVariant() {
      const colors = {
        accent: 'primary',
        primary: 'accent',
        secondary: 'grey lighten-3'
      };
      return colors[this.variant] || 'accent';
    },
    smBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>
