import Vue from "vue";

export const getMicrositeInfo = async ({ commit }, id) => {
  console.log("getMicrositeInfo");
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/membership/${id}`,
    });
    commit('getMicrositeInfo', data.membership);
    return {status: true, data: data.membership};
  } catch (error) {
    return {status: false, message: error.message};
  }
}
export const getMicrositeMembers = async ({ commit }, {page, id, perPage}) => {
  console.log(id);
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/membership/${id}/members?limit=${perPage}&page=${page}`,
    });
    commit('getMicrositeMembers', data);
    return {status: true, data: data};
  } catch (error) {
    return {status: false, message: error.message};
  }
}
export const getMicrositeMembersSearch = async ({ commit }, {page, id, perPage, name, role, branch }) => {
  console.log(id);
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/membership/${id}/members?limit=${perPage}&page=${page}&name=${name}&role=${role!=null?role:''}&branch=${branch!=null?branch:''}`,
    });
    commit('getMicrositeMembers', data);
    return {status: true, data: data};
  } catch (error) {
    return {status: false, message: error.message};
  }
}
export const getMicrositeEvents = async ({ commit }, {page, id}) => {
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/membership/${id}/events?limit=10&page=${page}`,
    });
    commit('getMicrositeEvents', data);
    return {status: true, data: data};
  } catch (error) {
    return {status: false, message: error.message};
  }
}
export const getMicrositeEventsSearch = async ({ commit }, {page, id, perPage, name}) => {
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/membership/${id}/events?limit=${perPage}&page=${page}&name=${name}`,
    });
    commit('getMicrositeEvents', data);
    return {status: true, data: data};
  } catch (error) {
    return {status: false, message: error.message};
  }
}

export const getEventsPageSearch = async ({ commit }, {page, id, perPage, name}) => {
  try {
    let {data} = await Vue.axios({
      method: 'get',
      url: `/microsite/eventsPage/${id}?limit=${perPage}&page=${page}&name=${name}`,
    });
    commit('getMicrositeEvents', data);
    return {status: true, data: data};
  } catch (error) {
    return {status: false, message: error.message};
  }
}

export const getMemberInformation = async ({commit}, id) =>{
  try{
    let data = await Vue.axios({
      method:'get',
      url: `/microsite/membership/member/${id}`
    });
    return {status: true, data: data.data.member}
  }catch(error){
    return {status: false, message: error.message }
  }
}