<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, off }">
      <v-text-field
        :value="setDate"
        filled
        dense
        :disabled="(user.role=='MEMBER' || user.role=='MEMBERSHIP_USER') && pickerDateLabel !='Fecha de Nacimiento'"
        rounded
        :label="pickerDateLabel"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="off"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-date-picker
      color="#fdb504"
      :value="setDate"
      :active-picker.sync="activePicker"
      :max="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10)
      "
      min="1900-01-01"
      @change="save"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props:{
    pickedDate:{
      type: String,
      default: ''
    },
    pickerDateLabel:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      activePicker: null,
      currentDate: '',
      menu: false,
    };
  },
computed:{
  ...mapState("user", ["user"]),
  setDate(){
   return this.pickedDate
  }
},

  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$emit('change', date, this.pickerDateLabel)
      this.$refs.menu.save(date);
    },


  },
};
</script>