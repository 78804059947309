import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                wDarkerBlue: '#192533',
                primary: '#1b273e',
                secondary: '#24b2da',
                accent: '#ffb500',
                wLightBlue: '#b7d4dc',
                wLightAccent: '#fef9ef',
                wLightGray: '#f9fafb',
                elementBackGray: '#f0f0f0',
                success: '#53a548',
                error: '#a52422',
                info: '#48beff',
                warning: '#fcfc62'
            },
            dark: true,
        }
    }
});