<template>
  <v-card color="white" class="pa-8">
    <v-row>
      <v-col cols="7">
        <div id="payment-element"></div>
      </v-col>
      <v-col>
        <v-card color="white" rounded="xl" class="pa-2">
          <v-row class="my-0 mx-2 wc-subtitle-1 font-weight-bold">
            Resumen de compra
          </v-row>
          <v-row class="my-2 mx-4 wc-body-2" justify="space-between">
            <div class="d-flex">
              <span class="font-weight-medium mr-2 accent--text">{{
                plan.balance
              }}</span>
              <span class="font-weight-medium">Registros</span>
            </div>
            <span class="font-weight-medium"> ${{ plan.price }} </span>
          </v-row>
          <v-divider class="mx-4"></v-divider>
          <v-row class="my-2 mx-4 wc-body-1" justify="space-between">
            <span class="font-weight-bold">Total</span>
            <span class="font-weight-bold"> ${{ plan.price }} </span>
          </v-row>
          <v-row class="my-4 mx-4">
            <v-btn block rounded color="#24b2da" @click="finishBuying()">
              <span class="white--text font-weight-bold">
                Finalizar compra
              </span>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_STRIPE_API_KEY,
      client_secret: "",
      appearance: {
        theme: "stripe",
      },
      paymentElementOptions: {
        layout: "accordion",
        business: {
          name: "WeChamber",
        },
      },
      stripe: null,
      elements: null,
    };
  },
  methods: {
    async generatePaymentIntent() {
      const { price, purchaseId, balance } = this.plan || {};
      const response = await this.axios({
        method: "POST",
        url: "/payments/internal/payment-intent",
        data: {
          amount: price,
          purchaseId: purchaseId,
          description: `Compra de ${balance} créditos WeChamber`
        },
      });
      console.log(response.data);
      this.clientSecret = response.data.paymentIntent.client_secret;
    },
    async finishBuying() {
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: process.env.VUE_APP_URL,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        console.log(error.message);
      } else {
        console.log("An unexpected error occurred.");
      }
    },
  },
  async mounted() {
    await this.generatePaymentIntent();

    this.stripe = await loadStripe(this.apiKey);

    this.elements = this.stripe.elements({
      appearance: this.appearance,
      clientSecret: this.clientSecret,
    });

    const paymentElement = this.elements.create(
      "payment",
      this.paymentElementOptions
    );
    paymentElement.mount("#payment-element");
  },
};
</script>

<style scoped>
.payment-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
