<template>
  <v-container fluid class="pa-0 ma-0">
    <!--Navegación pantallas de mayor tamaño-->
    <v-app-bar
      fixed
      app
      elevate-on-scroll
      :height="$vuetify.breakpoint.mdAndUp ? '100' : '65'"
      color="white"
    >
      <v-app-bar-nav-icon
        @click.stop="sidenav = !sidenav"
        class="d-md-none"
        style="position: absolute"
      ></v-app-bar-nav-icon>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
      <!--<div class="mx-3" :style="$vuetify.breakpoint.mdAndUp?'position: absolute;':''">-->
      <div class="mx-3">
        <v-img
          src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
          width="160px"
          height="auto"
          @click="home()"
        ></v-img>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <!--<v-toolbar-title justify="center">
      </v-toolbar-title>-->
      <v-toolbar-items class="hidden-sm-and-down absolute-center" dense>
        <v-btn
          style="text-transform: none"
          class="gray--text"
          v-for="item in menuItems"
          :key="item.title"
          plain
          :to="item.link"
          active-class="secondary--text font-weight-bold"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <div class="d-flex align-center">
          <v-btn
            class="font-weight-bold subtitle-1"
            rounded
            :fab="$route.name == 'customerBilling'"
            @click="trackLogin"
            :large="!($route.name == 'customerBilling')"
            color="secondary"
            style="text-transform: none"
            elevation="0"
            v-if="!$route.fullPath.includes('login')"
          >
            <v-icon v-if="$route.name == 'customerBilling'"> mdi-login </v-icon>
            <span v-else> Iniciar sesión </span>
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-app-bar>
    <!--Navegación pantallas de mayor tamaño-->
    <!--Navegación en tabletas-->
    <v-navigation-drawer v-model="sidenav" temporary fixed>
      <div class="my-15"></div>
      <v-list dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          :to="item.link"
          active-class="secondary white--text"
        >
          <v-list-item-content>
            <v-list-item-title
              v-if="item.name != 'Iniciar sesión'"
              style="text-transform: none"
              class="subtitle-1 font-weight-bold"
              >{{ item.title }}</v-list-item-title
            >
            <v-btn v-else>{{ item.title }}</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/login" active-class="secondary white--text">
          <v-list-item-content>
            <v-list-item-title
              style="text-transform: none"
              class="subtitle-1 font-weight-bold"
              >Iniciar sesión</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!--Navegación en tabletas-->
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      sidenav: false,
      menuItems: [
        { title: "Inicio", link: "/" },
        { title: "Paquetes y precios", link: "/paquetes-y-precios" },
        /*{ title: 'Registrarme', link: '/registro' },*/
        { title: "Facturar mi compra", link: "/facturacion-cliente" },
        //{ title: 'Iniciar sesión', link: '/login' },
      ],
    };
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    trackLogin() {
      console.log("tracking...");
      this.$gtag.event("user-login", {
        event_category: "User Interaction",
        event_label: "User login clicked",
        value: 1,
      });
      this.$router.push("login");
    },
  },
};
</script>
<style scoped>
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 959px) {
  header {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999 !important;
  }
  nav,
  main {
    margin-top: 4rem !important;
  }
  main {
    padding: 2rem;
  }
}
</style>