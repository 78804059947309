export function setBranches(state, branches) {
  state.branch = branches;
}

export function setFilteredBranch(state, capitulo){
  state.branch = capitulo;
}

export function clearBranches(state){
  state.branch = [];
}