import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const getAffiliates = async ( {commit}, query ) => {
  try {
    let url = `${Endpoints.memberAffiliates}?page=${query.page}&limit=${query.limit}`
    if(query.name){
      url = url +`&name=${query.name}`  
    }
    if(query.validationStatus){
      url = url +`&validationStatus=${query.validationStatus}`  
    }
    if (query.branch) {
      url = url +`&branch=${query.branch}`  
    }
    let affiliates = await Vue.axios({
      method: 'GET',
      url
    });
    commit('setAffiliates', affiliates);
    return {status: true, affiliates: affiliates};
  } catch (error) {
    return {status: false, message: error}
  }
}
export const getAffiliate = async ( {commit},{ membershipId, affiliationId} ) => {
  try {
    console.log(membershipId, affiliationId);
    let affiliate = await Vue.axios({
      method: 'GET',
      url: (Endpoints.memberAffiliate.replace(':membershipId', membershipId)).replace(':affiliationId', affiliationId),
    });
    return {status: true, affiliate: affiliate.data.user, currentBranch: affiliate.data.organization? affiliate.data.organization.company : ''};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const addAffiliate = async ( {commit}, { affiliate, organization, branch } ) => {
  try {
    let newAffiliate = await Vue.axios({
      method: 'POST',
      url: Endpoints.createMemberAffiliate+`/${organization}/${branch}`,
      data: affiliate
    });
    // commit('addAffiliate', newAffiliate.data.user);
    return {status: true, affiliate: newAffiliate.data.user};
  } catch (error) {
    console.log(error)
    if (error.response.status === 500) {
      return {status: false, message: "Este email ya esta registrado, intente con otro"};
    }
    return {status: false, message: error}
  }
}
export const updateAffiliate = async ( {commit}, affiliate ) => {
  try {
    let updatedAffiliate = await Vue.axios({
      method: 'PUT',
      url: Endpoints.memberAffiliatesUpdate.replace(':userId', affiliate.user._id),
      data: affiliate
    });
    commit('updateAffiliate', updatedAffiliate.data.user);
    return {status: true, affiliate: updatedAffiliate.data.user};
  } catch (error) {
    return {status: false, message: error}
  }
}
export const deleteAffiliate = async ( {commit}, affiliate ) => {
  try {
    await Vue.axios({
      method: 'DELETE',
      url: Endpoints.memberAffiliatesDelete.replace(':userId', affiliate._id)
    });
    commit('deleteAffiliate', affiliate);
    return {status: true, affiliate: affiliate};
  } catch (error) {
    return {status: false, message: error}
  }
}