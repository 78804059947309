export function setMembersPaginated(state, membersPaginated) {
  // Comprobar si el estado tiene datos de eventos
  if (state.membersPaginated.totalDocs==null) {
    state.membersPaginated = membersPaginated;
  } else {
    state.membersPaginated.members = membersPaginated.members
    state.membersPaginated.totalDocs = membersPaginated.totalDocs;
    state.membersPaginated.limit = membersPaginated.limit;
    state.membersPaginated.totalPages = membersPaginated.totalPages;
    state.membersPaginated.page = membersPaginated.page;
    state.membersPaginated.pagingCounter = membersPaginated.pagingCounter;
    state.membersPaginated.hasPrevPage = membersPaginated.hasPrevPage;
    state.membersPaginated.hasNextPage = membersPaginated.hasNextPage;
    state.membersPaginated.prevPage = membersPaginated.prevPage;
    state.membersPaginated.nextPage = membersPaginated.nextPage;
  }
}

export function setMembers(state, members) {
  state.members = members;
}

export function clearmembersPaginated(state) {
  state.membersPaginated = {
    members: [],
    totalDocs: null,
    limit: 8,
    totalPages: null,
    page: 1,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: null
  };
}