import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '../store';

const baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.baseURL = baseURL;

export const addToken = (token) => {
  axios.defaults.headers.common['authorization'] = 'Bearer ' + access_token;
}
// REQUEST INTERCEPTOR
axios.interceptors.request.use(
  request => {
    const token = store.state.user.token;
    if (token) request.headers.authorization = token;
    return request;
  },
  error => {
    Promise.reject(error);
  }
);

// RESPONSE INTERCEPTOR
axios.interceptors.response.use(async (response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 500 && error.response.data.error.message === 'jwt expired') {
    originalRequest._retry = true;
    const { email } = store.state.user.user;
    const user = { email: email }
    await store.dispatch('user/refreshToken', user)
    return axios(originalRequest);
  }
  if (error.response.data.error.status === 500 && error.response.data.error.message.includes('duplicate key error')) {
    await store.commit('notification/show', {
      text: "Este email ya está registrado",
      color: "error"
    });
    console.log(error.response.data.error.message);
  }
  return Promise.reject(error);
});

Vue.use(VueAxios, axios);