import Vue from "vue"

export const getAllMemberships = async({commit}) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: '/memberships/avaliable'
    })

    return {branches: data.branches}
  } catch (error) {
    return {message: error, status:false}
  }
}

export const getUserMemberships = async ({commit}) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: '/memberships'
    })

    return {memberships: data}
  } catch (error) {
    return {message: error, status:false}
  }
}

export const getMembershipById = async ({commit}, membershipId) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/memberships/${membershipId}`
    })
    commit('setMembershipProfile', data.membership)
    return {membership:data.membership}
  } catch (error) {
    return {message: error, status:false}
  }
}


export const createMembership = async ({commit}, params) => { // modificar
  try {
    let data = await Vue.axios({
      method: 'POST',
      url: '/memberships',
      data: {
        organization: params.branch
      }
    })
    return {status: true, data: data.data.newMembership, message: 'Membership created'}
  } catch (error) {
    return error
  }
}