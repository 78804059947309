import Vue from 'vue';
const fs = require('fs')

export const createBranch = async ({ commit }, branch) => {
  try {

    await Vue.axios({
      method: 'POST',
      url: '/branches',
      data: branch
    });

    return { status: 200, message: "Capítulo creado correctamente" };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al crear el capítulo", err: error };
  }
}

export const createBranchMember = async ({ commit }, { memberData, branchId }) => {
  try {
    let data = await Vue.axios({
      method: 'POST',
      url: '/branches/member',
      data: {
        "company": memberData.memberInformation.company,
        "externalId": "N/D",
        "email": memberData.memberInformation.email,
        "firstLastname": (memberData.memberInformation.lastName).split(" ")[0] || "N/D",
        "name": memberData.memberInformation.name,
        "profilePicture": "NOT_ASSIGNED",
        "stripeConnectId": "NOT_ASSIGNED",
        "invoiceServiceId": "NOT_ASSIGNED",
        "accountSetup": "NOT_STARTED",
        "invoiceSetup": false,
        "role": "MEMBER",
        "validationStatus": "EXPIRED",
        "hideTutorial": false,
        "memberInformation": memberData.memberInformation,
        "branch": branchId

      }
    });
    return { message: "miembro creado exitosamente", status: true, data: data }
  } catch (error) {
    return {
      message: error,
      status: false
    }
  }
}

export const updateBranchMember = async ({ commit }, { userData, id }) => {
  try {
    let data = await Vue.axios({
      method: 'PUT',
      url: '/branches/member/' + id,
      data: {
        "user": {
          "email": userData.memberInformation.email,
          "firstLastname": (userData.memberInformation.lastName).split(" ")[0] || "N/D",
          "name": userData.memberInformation.name,
          "memberInformation": userData.memberInformation,
        }
      }
    });
    return { message: "Miembro actualizado exitosamente", status: true, data: data };
  } catch (error) {
    return { message: error, status: false };
  }

}

export const getBranches = async ({ commit }) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: '/branches'
    });

    commit('setBranches', data.branches)
    return { status: 200, message: "Información consultada correctamente", branches: data.branches };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al obtener los usuarios", err: error };
  }
}

export const fetchChapterByName = async ({ commit }, branch) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: `/branches?name=${branch}`
    });

    commit('setFilteredBranch', data.branches)
    console.log(data.branches)
    return { status: 200, message: "Información consultada correctamente" };
  } catch (error) {
    return { status: false, message: "Ha ocurrido un problema al obtener los usuarios", err: error };
  }
}

export const setBranchName = async ({ commit }, branchData) => {
  try {
    await Vue.axios({
      method: 'PUT',
      url: '/branches/head-office/branch-name',
      data: {
        branch: branchData.branch,
        name: branchData.name
      }
    });

    return { status: 200, message: "Nombre actualizado", };
  } catch (error) {
    console.log(error);

    return { status: false, message: "Ha ocurrido un problema al obtener los usuarios", err: error };
  }
}
