import Vue from 'vue';
import Endpoints from '@/share/Endpoints';

export const createInternalInvoice = async ({ commit }, invoiceData) => {
  try {
    let { data } = await Vue.axios({
      method: 'POST',
      url: `${Endpoints.invoicesCreateInternal}`,
      data: invoiceData,
    });
    return { message: 'Factura generada correctamente', status: true, data };
  } catch (error) {
    return { message: error, status: false };
  }
};

export const fetchExpressInvoices = async ({ commit }) => {
  try {
    let { data } = await Vue.axios({
      method: 'GET',
      url: '/invoices/invoice-historics',
    });

    return { status: true, data: data.historics };
  } catch (error) {
    return { message: error, status: false };
  }
};
