import Vue from "vue";

export const setBenefits = async ({commit}, benefitsObject) => {
    try {
        console.log(benefitsObject);
        let data = await Vue.axios({
            method: 'POST',
            url: '/benefits',
            data: {
                benefit: {...benefitsObject}
            }
        })

        commit('pushIntoBenefits', data.data.data)

        return { status: true, message: "Beneficio cargado correctamente" }
    } catch (error) {
        return { message: "Error: Hubo un problema al cargar los datos, intenta nuevamente.", status: false }
    }
}

export const setBenefitsById = async ({commit}, benefit) =>{
    try {
        let data = await Vue.axios({
            method: 'PUT',
            url: `/benefits/update-benefit/${benefit.id}`,
            data: {
                ...benefit.form
            }
        })

        commit('pushIntoBenefits', data.data.data)

        return { status: true, message: "Beneficio cargado correctamente" }
    } catch (error) {
        return { message: "Error: Hubo un problema al cargar los datos, intenta nuevamente.", status: false }
    }
}

export const deleteBenefit = async ({commit}, ID) =>{
    try {
        let data = await Vue.axios({
            method: 'DELETE',
            url: `/benefits/delete-benefit/${ID}`,
        })

        commit('pushIntoBenefits', data.data.data)

        return { status: true, message: "Registro eliminado correctamente" }
    } catch (error) {
        return { message: "Error: Hubo un problema al eliminar los datos.", status: false }
    }
}

export const getBenefits = async ({commit}) => {
    try {
        let data = await Vue.axios({
            method: 'GET',
            url: '/benefits',
        })
        commit('updateBenefitList', data.data)
        return { status: true, data: data }
    } catch (error) {
        return { status: false }
    }
}

export const getUserBenefits = async ({commit}) => {
    try {
        let data = await Vue.axios({
            method: 'GET',
            url: '/benefits/user-benefits',
        })
        commit('updateBenefitList', data.data)
        return { status: true, data: data.data }
    } catch (error) {
        return { status: false }
    }
}