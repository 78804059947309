import Vue from "vue";
import Endpoints from "@/share/Endpoints";

export const passwordRecover = async ({ commit }, userEmail) => {
  try {
    const formData = new FormData();
    formData.append("email", userEmail);
    let { data } = await Vue.axios({
      method: "POST",
      url: Endpoints.passwordRecover,
      data: formData,
    });
    return data;
  } catch (error) {
    return {
      message: error,
      status: false,
    };
  }
};
export const passwordReset = async ({ commit }, userData) => {
  try {
    const formData = new FormData();
    formData.append("password", userData.password);
    formData.append("token", userData.token);
    let { data } = await Vue.axios({
      method: "POST",
      url: Endpoints.passwordReset,
      data: formData,
    });
    return data;
  } catch (error) {
    return {
      message: "Ha ocurrido un problema al cambiar la contraseña",
      status: false,
    };
  }
};

export const validateResetToken = async ({ commit }, token) => {
  try {
    let { data } = await Vue.axios({
        method: "POST",
        url: Endpoints.validateResetToken,
        data: {
            token: token
        }
    });
    return data;
  } catch (error) {
    return {
      message: "Token inválido, por favor generar otro",
      status: false,
    };
  }
};

export const validateNewUser = async ({ commit }, token) => {
  try {
    let { data } = await Vue.axios({
        method: "GET",
        url: `${Endpoints.validateNewUser}?token=${token}`
    });
    return {
      message: "Cuenta validada correctamente",
      status: true,
    };
  } catch (error) {
    return {
      message: "No se ha podido validar el usuario correctamente",
      status: false,
    };
  }
};

/**
 * Enviar correo de validación nuevamente
 * @param {*} param0 
 * @param {*} token 
 * @returns 
 */
export const resendEmailValidation = async ({ commit }, userEmail) => {
  try {
    let { data } = await Vue.axios({
        method: "POST",
        url: Endpoints.resendEmailValidaton,
        data: {
            user: {
              email: userEmail
            }
        }
    });
    return data;
  } catch (error) {
    return {
      message: "El correo no existe",
      status: false,
    };
  }
};