import Vue from 'vue';
import Endpoints from "@/share/Endpoints";
import qs from "query-string";

export const fetchOrganizationOrders = async ({ commit }, searchData) => {
    try {
        const endpoint = qs.stringifyUrl({
            url: Endpoints.allOrders,
            query: searchData,
          });

        let { data } = await Vue.axios({
            method: 'GET',
            url: endpoint
        });
        console.log('this.getOrganizationOrders',data)
        commit('setOrganizationOrders', data);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const fetchEventOrders = async ({ commit }, eventId) => {
    try {
        let { data } = await Vue.axios({
            method: 'GET',
            url: `/orders/${eventId}`
        });
        commit('setEventOrders', data);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}
