export const fiscalRegimenItems = [
  {
    text: '601 General de Ley Personas Morales',
    value: 601
  },
  {
    text: '603 Personas Morales con Fines no Lucrativos',
    value: 603
  },
  {
    text: '605 Sueldos y Salarios e Ingresos Asimilados a Salarios',
    value: 605
  },
  {
    text: '606 Arrendamiento',
    value: 606
  },
  {
    text: '607 Régimen de Enajenación o Adquisición de Bienes',
    value: 607
  },
  {
    text: '608 Demás Ingresos',
    value: 608
  },
  {
    text: '609 Consolidación',
    value: 609
  },
  {
    text: '610 Residentes en el Extranjero sin Establecimiento Permanente en México',
    value: 610
  },
  {
    text: '611 Ingresos por Dividendos (socios y accionistas)',
    value: 611
  },
  {
    text: '612 Personas Físicas con Actividades Empresariales y Profesionales',
    value: 612
  },
  {
    text: '614 Ingresos por Intereses',
    value: 614
  },
  {
    text: '615 Régimen de los ingresos por obtención de premios',
    value: 615
  },
  {
    text: '616 Sin Obigaciones Fiscales',
    value: 616
  },
  {
    text: '620 Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    value: 620
  },
  {
    text: '621 Incorporación Fiscal',
    value: 621
  },
  {
    text: '622 Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    value: 622
  },
  {
    text: '623 Opcional para Grupos de Sociedades',
    value: 623
  },
  {
    text: '624 Coordinados',
    value: 624
  },
  {
    text: '625 Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    value: 625
  },
  {
    text: '626 Régimen Simplificado de Confianza',
    value: 626
  },
  {
    text: '628 Hidrocarburos',
    value: 628
  },
  {
    text: '629 De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    value: 629
  },
  {
    text: '630 Enajenación de acciones en bolsa de valores',
    value: 630
  }
];

export const UsoCFDIItems = [
  /*{
    text: "G01 Adquisición de mercancías.",
    value: "G01"
  },
  {
    text: "G02 Devoluciones, descuentos o bonificaciones.",
    value: "G02"
  },*/
  {
    text: "G03 Gastos en general.",
    value: "G03"
  },
  {
    text: "S01 Sin efectos fiscales a la hora de descargar la factura.",
    value: "S01"
  }
  /*
  {
    text: "I01 Construcciones.",
    value: "I01"
  },
  {
    text: "I02 Mobiliario y equipo de oficina por inversiones.",
    value: "I02"
  },
  {
    text: "I03 Equipo de transporte.",
    value: "I03"
  },
  {
    text: "I04 Equipo de computo y accesorios.",
    value: "I04"
  },
  {
    text: "I05 Dados, troqueles, moldes, matrices y herramental.",
    value: "I05"
  },
  {
    text: "I06 Comunicaciones telefónicas.",
    value: "I06"
  },
  {
    text: "I07 Comunicaciones satelitales.",
    value: "I07"
  },
  {
    text: "I08 Otra maquinaria y equipo.",
    value: "I08"
  },
  {
    text: "D01 Honorarios médicos, dentales y gastos hospitalarios.",
    value: "D01"
  },
  {
    text: "D02 Gastos médicos por incapacidad o discapacidad.",
    value: "D02"
  },
  {
    text: "D03 Gastos funerales.",
    value: "D03"
  },
  {
    text: "D04 Donativos.",
    value: "D04"
  },
  {
    text: "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
    value: "D05"
  },
  {
    text: "D06 Aportaciones voluntarias al SAR.",
    value: "D06"
  },
  {
    text: "D07 Primas por seguros de gastos médicos.",
    value: "D07"
  }*/
]