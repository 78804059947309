import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const getExcecutives = async ( {commit} ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: Endpoints.executives
    });
    commit('getExcecutives', data);
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const createExecutive = async ( {commit}, executive ) => {
  try {
    let {data} = await Vue.axios({
      method: 'POST',
      url: Endpoints.executives,
      data: {
        executive: executive
      }
    });
    commit('createExecutive', data);
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const updateExecutive = async ( {commit}, executive ) => {
  try {
    let {data} = await Vue.axios({
      method: 'PUT',
      url: Endpoints.executives + `/${executive._id}`,
      data: {
        executive: executive
      }
    });
    commit('updateExecutive', data);
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const deleteExecutive = async ( {commit}, id ) => {
  try {
    let {data} = await Vue.axios({
      method: 'DELETE',
      url: Endpoints.executives + `/${id}`
    });
    commit('deleteExecutive', id);
    return {status: true, data};
  } catch (error) {
    return {message: error, status: false};
  }
}