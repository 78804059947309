<template>
  <!-- Información del usuario miembro -->
  <v-container>
    <v-row>
      <v-col
        v-for="(item, i) in itemsStatus"
        :key="i"
        :cols="$vuetify.breakpoint.mdAndDown ? '12' : 'auto'"
      >
        <v-card
          min-width="200"
          style="border-radius: 15px"
          :color="
            item.title === 'Estado'
              ? homologateStatusWithColor(item.content).color
              : item.color
          "
          :elevation="0"
        >
          <v-list-item class="info-title"> {{ item.title }} </v-list-item>
          <v-list-item
            class="d-flex justify-end text info-content"
            :class="
              item.title === 'Fecha de Vencimiento' && isInIndulgencePeriod
                ? 'red--text text--darken-4'
                : ''
            "
          >
            {{
              item.title === "Estado"
                ? homologateStatusWithColor(item.content).status
                : item.content
            }}
          </v-list-item>
        </v-card>
      </v-col>
      <v-col
        class="d-flex"
        :class="$vuetify.breakpoint.smAndUp ? 'align-end' : 'justify-center'"
      >
        <v-tooltip
          open-on-hover
          bottom
          activator="#constancyButton"
          max-width="250"
        >
          <span>Descarga aquí la constancia de membresía.</span>
        </v-tooltip>

        <div
          id="constancyButton"
          v-if="user.validationStatus == 'ACTIVE' && user.diplomaUrl"
        >
          <v-btn
            color="#1B273E"
            class="mx-2 white--text font-weight-bold"
            rounded
            @click.prevent="downloadConstancy()"
          >
            Constancia de membresía
            <v-icon small class="ml-2">mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col
        v-if="
          this.user.headOffice.id == '62b622c66a7441ae20159d7d' ||
          this.user.headOffice.id == '6297b0fec7bf8b6f826313be'
        "
        class="pt-6"
      >
        <v-sheet>
          <v-btn
            elevation="1"
            v-bind="$attrs"
            v-on="$on"
            style="
              background-color: #70b578;
              border-radius: 50px;
              font-weight: bold;
              color: white;
            "
            class="d-flex justify-center align-center"
            @click="redirectToValoresAMPI"
          >
            Ir a Valores AMPI
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-if="isInIndulgencePeriod">
      <v-col>
        <v-row class="my-0 mx-2">
          <v-icon color="red darken-3">mdi-alert</v-icon>
          <span class="font-weight-bold red--text text--darken-3">
            Estás en días de tolerancia
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { homologateStatusWithColor } from "@/common/hook/useHelper";

export default {
  data() {
    return {
      items: [
        { title: "Estado", content: "", color: "#9bf4d6" },
        {
          title: "Fecha de Vencimiento",
          content: "",
          color: "#ade1ef",
        },
        { title: "Miembro de", content: "", color: "#b6dae6" },
      ],
      tcolor: "#1b273f",
      profileCompany: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
    itemsStatus() {
      // buscar dentro del array aquella posición que contenga el formattedName y retorna su valor
      const getMemberDataValue = (memberData = [], formattedName = "") => {
        let index = [...memberData].findIndex(
          (elem) => elem.formattedName === formattedName
        );
        if (index === -1) {
          return "";
        }
        return memberData[index].value;
      };

      this.items[0].content = this.user.validationStatus;
      if (["66fb2d896646ca001cb93b3f"].includes(this.user.headOffice.id)) {
        this.items[2].content =
          "Grupo IMEF " + getMemberDataValue(this.user.memberData, "grupo");
      } else {
        this.items[2].content = this.profileCompany;
      }

      let inactivationDate = "";

      if (this.user.affiliation && this.user.affiliation.national) {
        if (this.user.affiliation.national.details) {
          const { affiliateInformation } =
            this.user.affiliation.national.details[0];
          if (affiliateInformation.inactiveDate) {
            inactivationDate = moment(affiliateInformation.inactiveDate).format(
              "MMMM DD YYYY"
            );
          }
        }
      }

      if (this.user.affiliation && this.user.affiliation.local) {
        if (this.user.affiliation.local.details) {
          const { affiliateInformation } =
            this.user.affiliation.local.details[0];
          if (
            affiliateInformation.inactiveDate &&
            affiliateInformation.inactiveDate < inactivationDate
          ) {
            inactivationDate = moment(affiliateInformation.inactiveDate).format(
              "MMMM DD YYYY"
            );
          }
        }
      }

      this.items[1].content = inactivationDate;
      // if (this.user.headOffice.hierarchyType != 'TWO_LEVELS') {
      //   this.items.splice(2, 1);
      // }
      return this.items;
    },
    isInIndulgencePeriod() {
      let nationalInactivationDate = this.user.affiliation.national
        ? this.user.affiliation.national.details[0]
          ? this.user.affiliation.national.details[0].affiliateInformation
            ? this.user.affiliation.national.details[0].affiliateInformation
                .inactiveDate
            : null
          : null
        : null;

      let localInactivationDate = this.user.affiliation.local
        ? this.user.affiliation.local.details[0]
          ? this.user.affiliation.local.details[0].affiliateInformation
            ? this.user.affiliation.local.details[0].affiliateInformation
                .inactiveDate
            : null
          : null
        : null;

      let nationalIndulgence = true;
      let localIndulgence = true;

      if (nationalInactivationDate) {
        nationalIndulgence = new Date(nationalInactivationDate) < new Date();
      }

      if (localInactivationDate) {
        localIndulgence = new Date(localInactivationDate) < new Date();
      }

      return nationalIndulgence && localIndulgence;
    },
  },
  methods: {
    ...mapActions("member", ["fetchMemberStatus"]),
    ...mapActions("membershipProfile", ["getMembershipById"]),
    homologateStatusWithColor,
    redirectToValoresAMPI() {
      window.open("https://valoresampi.mx/", "_blank");
    },
    async downloadConstancy() {
      try {
        window.location.href = this.user.diplomaUrl;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (![{}, undefined].includes(this.userMembership)) {
      this.profileCompany = this.userMembership.organization.company;
    } else {
      this.profileCompany = this.user.branch;
    }
    console.log("bro", this.user.headOffice.id);
  },
};
</script>

<style scoped>
.info-title {
  color: #1e2e3e;
  font-weight: 600;
}

.info-content {
  color: #1e2e3e;
  font-weight: 900;
}
</style>