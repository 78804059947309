<template>
  <v-container fluid class="pa-0">
    <!-- Navegación izquierda -->
    <v-navigation-drawer
      v-model="sidenav"
      app
      fixed
      mobile-breakpoint="960"
      class="step-2"
    >
      <v-list nav dense>
        <v-list-item class="d-flex justify-center py-5 mt-14">
          <v-img
            v-if="
              user.profilePicture != '' && user.profilePicture != 'NOT_ASSIGNED'
            "
            :src="user.profilePicture"
            max-width="150"
          ></v-img>
          <v-img
            v-else
            src="@/assets/images/weChamber/logo-WC-microsite.svg"
            max-width="100"
            contain
          ></v-img>
        </v-list-item>
        <!--<v-list-item v-if="$vuetify.breakpoint.mdAndDown">
          <v-img src="@/assets/images/weChamber/logo-WC.png"></v-img>
        </v-list-item>-->
      </v-list>
      <v-list nav rounded dense>
        <v-list-item-group v-model="item">
          <template v-if="user.role === 'ORGANIZATION'">
            <div v-for="(item, i) in roleNavigations" :key="i">
              <v-list-item
                v-if="!item.disabled"
                :to="item.link"
                class="px-6 px-md-2"
                active-class="white--text wDarkerBlue"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                  <v-badge
                    v-show="item.badge"
                    color="transparent"
                    overlap
                    dark
                    offset-y="20"
                  >
                    <template v-slot:badge>
                      <v-icon color="red lighten-2" size="20">
                        mdi-star mdi-spin
                      </v-icon>
                    </template>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-title
                  >{{ item.title }}
                  <p
                    v-if="item.disabled"
                    class="caption secondary--text font-weight-bold mb-0"
                  >
                    Próximamente
                  </p>
                </v-list-item-title>
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                :offset-x="$vuetify.breakpoint.mdAndUp?'70':'40'"
                offset-y="8"
              >
                <template v-slot:badge>
                  <p class="caption secondary--text font-weight-bold mb-0">Pronto...</p>
                </template>  
              </v-badge>-->
                <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                overlap
                dark
                offset-y="20"
              >
                <template v-slot:badge>
                  <p class="secondary--text">Próximamente</p>
                </template>  
              </v-badge>-->
              </v-list-item>
            </div>
          </template>

          <div v-else>
            <v-list-item
              v-for="(item, i) in roleNavigations"
              :key="i"
              :to="item.link"
              class="px-6 px-md-2"
              active-class="white--text wDarkerBlue"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                <v-badge
                  v-show="item.badge"
                  color="transparent"
                  overlap
                  dark
                  offset-y="20"
                >
                  <template v-slot:badge>
                    <v-icon color="red lighten-2" size="20">
                      mdi-star mdi-spin
                    </v-icon>
                  </template>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }} </v-list-item-title>
              <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                :offset-x="$vuetify.breakpoint.mdAndUp?'70':'40'"
                offset-y="8"
              >
                <template v-slot:badge>
                  <p class="caption secondary--text font-weight-bold mb-0">Pronto...</p>
                </template>  
              </v-badge>-->
              <!--<v-badge
                v-show="item.disabled"
                color="transparent"
                overlap
                dark
                offset-y="20"
              >
                <template v-slot:badge>
                  <p class="secondary--text">Próximamente</p>
                </template>  
              </v-badge>-->
            </v-list-item>
          </div>

          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            to="/escanear-qr"
            class="px-6 px-md-2"
            active-class="white--text wDarkerBlue"
          >
            <v-list-item-icon>
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Escanear código QR </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            class="px-6 px-md-2 red darken-4"
            @click="logOutDialog = true"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text font-weight-bold"
              >Cerrar sesión</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- / Navegación izquierda -->
    <!-- Menú derecho -->
    <v-col
      cols="12"
      :class="
        $vuetify.breakpoint.mdAndUp
          ? 'd-flex justify-end wLightGray'
          : 'd-flex justify-end'
      "
      class="mb-8"
    >
      <!--<v-list-item>
        <p>Evento perrón dadsadasd das  sad  dd asd asddsa </p>
      </v-list-item>-->
      <!--<v-list-item-icon v-if="$vuetify.breakpoint.mdAndDown">
        <v-img
          max-height="40"
          max-width="40"
          src="@/assets/images/weChamber/logo-WC.png"
        ></v-img>
      </v-list-item-icon>-->
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="mx-2"
        color="primary"
        icon
        large
        @click="sidenav = true"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <account-menu @logOut="closeLogOutDialog"></account-menu>
    </v-col>
    <PersonalizedDialog
      :dialog="logOutDialog"
      @save="_logout"
      @discard="logOutDialog = false"
    >
      <template v-slot:alertContent>
        <p class="title text-center">¿Está seguro que desea cerrar sesión?</p>
      </template>
    </PersonalizedDialog>
    <!-- / Menú derecho -->
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ActionButton from "@/components/shared/ActionButton";
import OrganizerBalance from "./DashboardNavbar/OrganizerBalance";
import AccountMenu from "./DashboardNavbar/AccountMenu.vue";
import Endpoints from "@/share/Endpoints";
import PersonalizedDialog from "../shared/PersonalizedDialog.vue";

export default {
  name: "BranchNavbar",
  components: {
    ActionButton,
    OrganizerBalance,
    PersonalizedDialog,
    AccountMenu,
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      sidenav: false,
      bottomNav: 0,
      item: 1,
      drawer: false,
      logOutDialog: false,
      tabs: [
        { title: "Editar mis datos", icon: "mdi-account-edit-outline" },
        { title: "Cerrar sesión", icon: "mdi-logout-variant" },
      ],
      navigations: [],
      roleNavigations: [
        {
          icon: "mdi-view-dashboard-outline",
          title: "Panel de inicio",
          link: "/panel-inicio",
          disabled: true,
        },
        {
          icon: "mdi-calendar-outline",
          title: "Mis eventos",
          link: "/mis-eventos",
          disabled: true,
        },
        {
          icon: "mdi-badge-account-horizontal-outline",
          title: "Mis Miembros",
          link: "/miembros",
          disabled: true,
        },
        {
          icon: "mdi-wallet-outline",
          title: "Mis Ingresos", //dispersiones
          link: "/dispersiones",
          disabled: true,
        },
        // {
        //   icon: "mdi-face-man-shimmer-outline",
        //   title: "Mis membresías",
        //   link: "/mis-membresias",
        //   disabled: true,
        // },
      ],
      /*
      roleNavigations: [
        {
          icon: "admin_panel_settings",
          title: "Permisos",
          link: "/permisos",
          disabled: true,
        },
        {
          icon: "store",
          title: "Mis Capítulos",
          link: "/capitulos",
          disabled: false,
        },
      ],
      */
    };
  },
  methods: {
    ...mapMutations("alert", ["showAlert"]),
    ...mapMutations("user", ["logout"]),
    _logout() {
      this.logout();
      this.$router.push("/login");
    },
    closeLogOutDialog() {
      this.logOutDialog = true;
    },
    async getMethodPaymentActive() {
      const response = await this.axios.get(
        Endpoints.excecuteGetMethodPayment.replace(/:userID/, this.user._id)
      );
      const data =
        response.data.methods[
          response.data.methods.findIndex(
            (item) => item.id == "bank_transfer_payments"
          )
        ].active;
      this.showAlert(data);
    },
    formatPermission(permission) {
      return `${permission.action}-${permission.subject}`;
    },

    getNav(permission) {
      let validation = {
        "create-Event": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
        ],
        "read-Membership": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
        ],
        "read-Event": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
        ],
        "read-Membership": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
        ],
        "read-Permission": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Payouts": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Purchase": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
        "read-Transaction": [
          {
            icon: "mdi-view-dashboard",
            title: "Panel de inicio",
            link: "/panel-inicio",
          },
          {
            icon: "fa fa-calendar",
            title: "Mis eventos",
            link: "/mis-eventos",
          },
          {
            icon: "mdi-face-man-shimmer-outline",
            title: "Mis membresías",
            link: "/mis-membresias",
            disabled: true,
          },
          {
            icon: "mdi-comment-question-outline",
            title: "Mesa de ayuda",
            link: "/mesa-ayuda",
          },
          //{ icon: 'mdi-chart-bar', title: 'Estadísticas', link: 'estadistica' },
          {
            icon: "mdi-credit-card",
            title: "Mis compras",
            link: "/facturacion",
          },
          {
            icon: "mdi-chart-timeline-variant",
            title: "Dispersiones",
            link: "/dispersiones",
          },
          {
            icon: "mdi-account",
            title: "Mi cuenta",
            link: "/mi-cuenta",
            badge: true,
          },
          {
            icon: "admin_panel_settings",
            title: "Permisos",
            link: "/permisos",
          },
        ],
      };

      return validation[permission];
    },
  },
  async mounted() {
    // Validar si el usuario ya conectó su cuenta de stripe y de facturación para quitar la ayuda con las secciones que falta modificar
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.sidenav = true;
    }
    await this.getMethodPaymentActive();
  },
};
</script>
<style scoped>
.rounded-container {
  border-radius: 6rem !important;
}
</style>
