var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:`
      d-flex
      justify-around
      align-center
      px-md-5
      mx-md-5
      inner-spacing
      ${_vm.classes.join(' ')}
  `}),_c('BalancePlans',{attrs:{"open":_vm.showBalanceDialog},on:{"close":function($event){_vm.showBalanceDialog = false},"open":function($event){_vm.showBalanceDialog = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }