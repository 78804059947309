import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2'
import moment from 'moment'
import VueGtag from 'vue-gtag'
import {formatDate} from './utils/dates'
import VueShepherd from 'vue-shepherd';
import io from 'socket.io-client';
import Chat from 'vue-beautiful-chat'
//const socket = io('http://localhost:3000'); // Replace with your server URL

//Vue.prototype.$socket = socket;

moment.locale('es');
Vue.prototype.moment = moment

require('./plugins')
Vue.use(VueClipboard)
Vue.use(VueShepherd);
Vue.use(Chat)

    // Archivo global de css
    import "@/assets/css/main.css";

    // Font awemsome
    import '@fortawesome/fontawesome-free/css/all.css';
import './registerServiceWorker';

Vue.config.productionTip = false
Vue.prototype.window = window;


console.log(VueGtag);

Vue.use(VueGtag, {
  config: { id: "G-WE7JYK3W5B" },
  appName: 'App',
  enabled: true,
  pageTrackerScreenviewEnabled: true,
  onBeforeTrack (){
    console.log('tracking started...');
  }
});
/**
 * Filtro para mostrar contenido en formato de moneda nacional (MXN).
 * @param {(string|number)} value   El valor a convertir a moneda nacional.
 */
Vue.filter('quantityFormat', (value) => {
  if (!value || (typeof value != "string" && typeof value != "number")) return ''

  if (typeof value === 'string')
    value = Number(value);

  // return new Intl.NumberFormat("es-MX").format(value)
  // return value.toLocaleString('es-MX');
  let str = value.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
})


/**
 * Filtro para mostrar contenido en formato de moneda nacional (MXN).
 * @param {(string|number)} value   El valor a convertir a moneda nacional.
 */
 Vue.filter('currency', (value) => {
    if (!value || (typeof value != "string" && typeof value != "number")) return ''
    if (typeof value === 'string') 
      value = Number(value);
  
    // return new Intl.NumberFormat("es-MX").format(value)
    // return value.toLocaleString('es-MX');
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  })

  Vue.filter('formatDate', formatDate)
  
new Vue({
  router,
  store,
  vuetify,
  iconfont: 'fa',
  render: h => h(App)
}).$mount('#app')