import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const fetchOrganizationPurchases = async ({ commit }, ticketsSearchData) => {
    try {
        let {data} = await Vue.axios({
            method: 'GET',
            url: `${Endpoints.purchases}?page=1&limit=10`
        });
        commit('setPurchasesData', data.purchases);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}