var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_vm._l((_vm.itemsStatus),function(item,i){return _c('v-col',{key:i,attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : 'auto'}},[_c('v-card',{staticStyle:{"border-radius":"15px"},attrs:{"min-width":"200","color":item.title === 'Estado'
            ? _vm.homologateStatusWithColor(item.content).color
            : item.color,"elevation":0}},[_c('v-list-item',{staticClass:"info-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-list-item',{staticClass:"d-flex justify-end text info-content",class:item.title === 'Fecha de Vencimiento' && _vm.isInIndulgencePeriod
              ? 'red--text text--darken-4'
              : ''},[_vm._v(" "+_vm._s(item.title === "Estado" ? _vm.homologateStatusWithColor(item.content).status : item.content)+" ")])],1)],1)}),_c('v-col',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndUp ? 'align-end' : 'justify-center'},[_c('v-tooltip',{attrs:{"open-on-hover":"","bottom":"","activator":"#constancyButton","max-width":"250"}},[_c('span',[_vm._v("Descarga aquí la constancia de membresía.")])]),(_vm.user.validationStatus == 'ACTIVE' && _vm.user.diplomaUrl)?_c('div',{attrs:{"id":"constancyButton"}},[_c('v-btn',{staticClass:"mx-2 white--text font-weight-bold",attrs:{"color":"#1B273E","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadConstancy()}}},[_vm._v(" Constancia de membresía "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-download")])],1)],1):_vm._e()],1),(
        this.user.headOffice.id == '62b622c66a7441ae20159d7d' ||
        this.user.headOffice.id == '6297b0fec7bf8b6f826313be'
      )?_c('v-col',{staticClass:"pt-6"},[_c('v-sheet',[_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex justify-center align-center",staticStyle:{"background-color":"#70b578","border-radius":"50px","font-weight":"bold","color":"white"},attrs:{"elevation":"1"},on:{"click":_vm.redirectToValoresAMPI}},'v-btn',_vm.$attrs,false),_vm.$on),[_vm._v(" Ir a Valores AMPI ")])],1)],1):_vm._e()],2),(_vm.isInIndulgencePeriod)?_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"my-0 mx-2"},[_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"font-weight-bold red--text text--darken-3"},[_vm._v(" Estás en días de tolerancia ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }