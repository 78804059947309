import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const fetchAffiliationById = async ( {commit}, affiliationId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/affiliations/${affiliationId}`
    });
    commit('setAffiliationSelected', data.affiliation);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}


export const fetchAffiliationMicrositeData = async ( {commit}, affiliationId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `${Endpoints.affiliationMicrositeData}/${affiliationId}`
    });
    commit('setAffiliationMicrositeData', data.affiliation);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}

export const fetchOrganizationAffiliationsPaginated = async ( {commit}, affiliationsSearchData ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/affiliations?page=${affiliationsSearchData.page}&limit=${affiliationsSearchData.perPage}&name=${affiliationsSearchData.name}`
    });
    if (data.message && data.message.name && data.message.name == "TokenExpiredError") {
      return {message: "El token a expirado", status: false};
    } else {
      commit('setOrganizationAffiliationsPaginated', data);
    }
    return {message: "Membresías obtenidas de forma correcta", status: true, data: data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const fetchOrganizationAffiliations = async ( {commit}, affiliationsSearchData ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/affiliations?page=${affiliationsSearchData.page}&limit=${affiliationsSearchData.perPage}`
    });
    if (data.message && data.message.name && data.message.name == "TokenExpiredError") {
      return {message: "El token a expirado", status: false};
    } else {
      commit('setOrganizationAffiliations', data.affiliations);
    }
    return {message: "Membresías obtenidas de forma correcta", status: true};
  } catch (error) {
    console.log(error);
    return {message: error, status: false};
  }
}

export const createAffiliation = async ( {commit}, dataAffiliation) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: '/affiliations',
      data: {
        affiliation: {
          name: dataAffiliation.name,
          description: dataAffiliation.description,
          micrositeConfiguration: dataAffiliation.micrositeConfiguration,
          paymentModality: dataAffiliation.paymentModality,
          installments: dataAffiliation.installments,
          offerBilling: dataAffiliation.offerBilling,
          comissionTransfer: dataAffiliation.comissionTransfer,
          affiliationTypes: dataAffiliation.affiliationTypes,
          organization: dataAffiliation.organization,
        }
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateAffiliation = async ( {commit}, affiliation) => {
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `/affiliations/${affiliation._id}`,
      data: {
        affiliation
      },
    });
    return { status: true, message: "Membresía actualizada correctamente", data: response.data };
  } catch (error) {
    return {status: false, message: "No se actualizó la membresía correctamente"};
  }
}

export const deleteAffiliation = async ( {commit}, affiliationId) => {
  try {
    let response = await Vue.axios({
      method: 'DELETE',
      url: `${Endpoints.affiliations}/${affiliationId}`
    });
    return {status: true, message: "Membresía eliminada correctamente", data: response}
  } catch (error) {
    return {status: false, message: error}
  }
}

export const publishAffiliation = async ( {commit}, dataAffiliation) => {
  console.log(dataAffiliation);
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `/affiliations/${dataAffiliation.id}`,
      data: {
        affiliation: dataAffiliation
      },
    });
    console.log(response);
    return { status: true, message: "Membresía actualizada correctamente", data: response.data };
  } catch (error) {
    console.log(error);
    return {status: false, message: "No se actualizó la membresía correctamente"};
  }
}

/**
 * Función para agregar una imagen de un patrocinador a una membresía
 * @param {String} sponsorData Datos del patrocinador y de la membresía
 */
export const addAffiliationSponsor = async ( {commit}, sponsorData) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: `${Endpoints.addAffiliationSponsor}/${sponsorData.affiliationId}`,
      data: {
        name: sponsorData.name,
        logo: sponsorData.logo,
        url: sponsorData.url,
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * Función para remover una imagen de un patrocinador de una membresía
 * @param {String} sponsorData Datos del patrocinador y de la membresía
 */
 export const removeAffiliationSponsor = async ( {commit}, sponsorData) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: `${Endpoints.removeAffiliationSponsor}/${sponsorData.affiliationId}`,
      data: {
        sponsorId: sponsorData.sponsorId,
        logo: sponsorData.logo,
      }
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const fetchAffiliationFaqs = async ( {commit}, affiliationId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `${Endpoints.affiliationFaqs}/${affiliationId}`
    });
    commit('setAffiliationFaqs', data.faqs);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}

export const createAffiliationFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: Endpoints.affiliationFaqs,
      data: {
        faq: {
          question: dataFaq.question,
          answer: dataFaq.answer,
          affiliation: dataFaq.affiliationId,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente creada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const updateAffiliationFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `${Endpoints.affiliationFaqs}/${dataFaq.id}`,
      data: {
        faq: {
          question: dataFaq.question,
          answer: dataFaq.answer,
          status: dataFaq.status,
          affiliation: dataFaq.affiliation,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente actualizada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const deleteAffiliationFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'DELETE',
      url: `${Endpoints.affiliationFaqs}/${dataFaq.id}`,
      data: {
        faq: {
          affiliation: dataFaq.affiliation,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente eliminada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}