<template>
  <div>
    <v-sheet
    v-click-outside="clickOutside"
    elevation="1"
    width="280"
    height="auto"
    x-large
    class="
    wDarkerBlue--text
    pr-2
    text-capitalize
    d-flex
    justify-space-around
        align-center
        py-1
        clickable
      "
      color="white"
      style="font-weight: 800"
      :class="changeClass"
      >
      <v-btn to="/mesa-ayuda" @click="menuExpand = false" icon :ripple="false">
        <v-icon color="wDarkerBlue"> mdi-comment-question-outline </v-icon>
      </v-btn>
      <span class="pointer" @click="menuExpand = !menuExpand"> Mi Cuenta </span>
      <v-avatar size="42" @click="menuExpand = !menuExpand">
        <v-img
        src="@/assets/images/weChamber/wechamber-blue-logo-placeholder.png"
        ></v-img>
      </v-avatar>
    </v-sheet>
    
    <v-expand-transition >
      <v-card
        elevation="1"
        v-show="menuExpand"
        class="rounded-b-xl rounded-t-0"
        width="280"
        style="z-index: 1; position: absolute; "
        >
        <v-list dense flat expand>
          <v-list-item-group color="primary" class="px-5">
            <v-list-item v-if="user.permissions.Account && user.permissions.Account.update">
              <v-list-item-content>
                <v-list-item-title
                  @click="balancePlansOpen = true"
                  style="font-size: 16px"
                  >Comprar más créditos</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              
              <v-list-item
              to="/facturacion"
              v-if="
                this.user.permissions.Payouts &&
                this.user.permissions.Payouts.read
              "
            >
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px"
              >Histórico de compras</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item to="/mi-cuenta" v-if="user.permissions.Account && user.permissions.Account.update">
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px"
              >Editar mi cuenta</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item to="/permisos" v-if="this.user.role!='BRANCH' && this.user.role!='ORGANIZATION_USER'">
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px"
              >Editar mi equipo</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item @click="clickLogOutDialog">
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px"
              >Cerrar sesión</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        </v-list>
      </v-card>
    </v-expand-transition>
    <BalancePlans
      :open="balancePlansOpen"
      @open="balancePlansOpen = true"
      @close="balancePlansOpen = false"
    />
  </div>
</template>
  <script>
import BalancePlans from "./OrganizerBalance/BalancePlans.vue";
import { mapState } from "vuex";

export default {
  components: {
    BalancePlans,
  },
  data() {
    return {
      menuExpand: false,
      balancePlansOpen: false,
    };
  },
  methods: {
    clickOutside() {
      this.menuExpand = false;
    },
    clickLogOutDialog() {
      this.$emit("logOut");
      //logOutDialog = true
    },
  },
  computed: {
    ...mapState('user',['user']),
    changeClass() {
      return this.menuExpand ? "rounded-t-xl" : "rounded-xl";
    },
  },
};
</script>
  