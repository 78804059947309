<template>
  <v-container fluid>
    <v-row :class="$vuetify.breakpoint.smAndDown||'d-flex justify-center'">
      <!-- Members to assign -->
      <v-col sm="12" md="5" class="pa-0">
        <v-card :class="cardClass" elevation="1" max-height="360">
          <div class="d-flex justify-space-between">
            <span class="card_tittle">Miembros Disponibles: {{ memberAvailableTotal }}</span> 
            <span class="member_counter" v-if="memberAddedCounter>0">+{{ memberAddedCounter }}</span>
          </div>
          <v-divider></v-divider>
          <v-text-field
            :background-color="color.gray"
            placeholder="Buscar miembro"
            rounded
            v-model="searchAvailable.name"
            height="40"
            @keyup.enter="msearchAvailable"
            hide-details
          >
            <v-icon @click="msearchAvailable" slot="append" class="pointer pt-1">mdi-magnify </v-icon>
          </v-text-field>
          <div class="list_member">
            <v-list class="pa-0 mt-2" >
              <v-list-item dense v-for="item,i in memberAvailableList" :key="i" class="pa-0 d-flex">
                <div class="me-auto d-flex">
                  <v-list-item-avatar :color="color.primaryVariant" size="29" class="my-0">
                    <v-img v-if="item.memberImage!='NOT_ASSIGNED'" :src="item.memberImage"></v-img>
                    <v-icon v-else color="white">mdi-account</v-icon>
                  </v-list-item-avatar>
                  <span >{{ item.fullName }}</span>
                </div>
                <div>
                  <v-checkbox hide-details :color="color.primaryVariant" @click="assignmember(item, 'Assigned')" class="pa-0 ma-0" :ripple="false" v-model="item.selected"></v-checkbox>
                </div>                 
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <!-- Action buttons -->
      <v-col :cols="$vuetify.breakpoint.smAndDown?12:'auto'" 
      class="px-0 d-flex"
      :class="$vuetify.breakpoint.smAndDown? 'align-center justify-space-around': 'flex-column justify-center mx-3'">
        <v-btn 
        small
        fab
        :disabled="!(memberAddedCounter>0)"
        elevation="0" 
        :loading="load"
        @click="moveToAssigned"
        :ripple="false" 
        :color="color.active"> 
          <v-icon>
            {{'mdi-arrow-'+($vuetify.breakpoint.smAndDown?'down':'right') }}
          </v-icon>
        </v-btn>
        <v-btn 
        small 
        fab 
        :disabled="!(memberRemoveCounter>0)"
        elevation="0" 
        :loading="load"
        @click="removeAssigned"
        :ripple="false" 
        :class="$vuetify.breakpoint.smAndDown||'mt-4'" color="#f49bb1"> 
          <v-icon>
            {{'mdi-arrow-'+($vuetify.breakpoint.smAndDown?'up':'left') }}
          </v-icon>
        </v-btn>
      </v-col>
      <!-- Members assigned -->
      <v-col sm="12" md="6" class="pa-0">
        <v-card :class="cardClass" height="275" elevation="1">
          <div class="d-flex justify-space-between">
            <span class="card_tittle">Miembros Vinculados: {{ membersVinculatedTotal }}</span>
            <span class="member_uncounter" v-if="memberRemoveCounter>0">-{{ memberRemoveCounter }}</span>
          </div>
          <v-divider></v-divider>
          <v-list class="vinculated_member" two-line>
            <v-list-item v-for="item,i in memberAffiliateList" :key="i" class="pl-0 d-flex">
              <div class="me-auto d-flex">
                  <v-list-item-avatar :color="color.primaryVariant" size="29" class="my-0">
                    <v-img v-if="item.memberImage!='NOT_ASSIGNED'" :src="item.memberImage"></v-img>
                    <v-icon v-else color="white">mdi-account</v-icon>
                  </v-list-item-avatar>
                  <div>
                    <span class="member_name font-weight-bold">{{ item.fullName }}</span><br>
                      <span>{{ item.email }}</span>
                  </div>
                </div>
                <div>
                  <v-checkbox hide-details :color="color.primaryVariant" @click="assignmember(item, 'unAssigned')"  class="pa-0 ma-0" :ripple="false" v-model="item.selected"></v-checkbox>
                </div>  
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { colors } from "@/constants/colors.js";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {
    memberID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardClass: "pa-5",
      load: false,
      color:colors,
      //tittle counters  
      memberAddedCounter:0,
      memberRemoveCounter:0,
      //total items 
      memberAvailableTotal: 0,
      membersVinculatedTotal:0,
      //member list
      memberAvailableList: [],
      memberAffiliateList: [],
      //auxiliar members selected list 
      membersToAssign:[],
      membersToUnassign:[],
      //search Criteria
      searchAvailable:{
        name: "",
        branch: "",
        page: 1,
        perPage: 20,
        validationStatus: "",
        role : 'MEMBERSHIP_USER',
        select: 'name firstLastname email profilePicture _id',
        isAvailable: true 
      },
      getAffiliates:{
        name: "",
        branch: "",
        page: 1,
        perPage: 30,
        validationStatus: "",
        role : 'MEMBERSHIP_USER',
        select: 'name firstLastname email profilePicture _id',
        memberID: '' 
      }
    };
  },
 async mounted(){
    this.getAffiliates.memberID = this.memberID
    await this.fetchAll()
  },
  methods:{
    ...mapActions("members", [
      "getBranchMembersPaginated",
    ]),
    async fetchAll(){
      this.load=true
      await this.msearchAvailable()
      await this.mgetAffiliates()
      this.load=false
    },
    async filtrarMiembros(search){
      let response = await this.getBranchMembersPaginated(
        search
      );
      if (response.status === true) {
        return response
      } else {
        console.log("Error en la petición: " + response.message);
      }
      
    },

    async moveToAssigned(){
      if (this.memberAddedCounter>0) {
        const arrayList = this.membersToAssign.map(e=>e.id)
        await this.axios({
          method:'put',
          url:'membership-users/relation',
          data:{
           memberID: this.memberID,
           ids: arrayList,
           action: 'Assigned'
          }
        });
        this.memberAddedCounter=0
         this.membersToAssign=[]
        await this.fetchAll()
      }
    },
    async removeAssigned(){
      if (this.memberRemoveCounter>0) {
        const arrayList = this.membersToUnassign.map(e=>e.id)
        await this.axios({
          method:'put',
          url:'membership-users/relation',
          data:{
           memberID: this.memberID,
           ids: arrayList,
           action: 'unAssigned'
          }
        });
        this.memberRemoveCounter=0
        this.membersToUnassign=[]
        await this.fetchAll()
      }
    },



    assignmember(member, ftype){
      switch (ftype) {
        case 'Assigned':
            if (!this.membersToAssign.includes(member) && member.selected) {
            this.membersToAssign.push(member); this.memberAddedCounter+=1
            }
            if(!member.selected){
              const i = this.membersToAssign.findIndex(e=>e==member)
              this.membersToAssign.splice(i,1); this.memberAddedCounter-=1
            }   
          break;
        case 'unAssigned':
          if (!this.membersToUnassign.includes(member) && member.selected) {
            this.membersToUnassign.push(member); this.memberRemoveCounter+=1
          }
          if (!member.selected) {
            const i = this.membersToUnassign.findIndex(e=>e==member)
            this.membersToUnassign.splice(i,1); this.memberRemoveCounter-=1
          }
          break;
      }
    },

    async msearchAvailable() {
      this.memberAvailableList=[]
      await this.filtrarMiembros(this.searchAvailable)
      if (this.membersPaginated.members.length!==0) {
        this.membersPaginated.members.forEach(e=>{
          this.memberAvailableList.push(
            {
              memberImage: e.profilePicture,
              fullName: e.name+' '+e.firstLastname,
              email:e.email,
              id: e._id,
              selected: this.membersToAssign.findIndex(j=>j.id==e._id)>=0||false,
            },
            )
        });     
      };
      this.searchAvailable.name=''
      this.memberAvailableTotal = this.membersPaginated.totalDocs
    },
    async mgetAffiliates() {
      this.memberAffiliateList=[];this.getAffiliates.memberID=this.memberID
      await this.filtrarMiembros(this.getAffiliates)
      this.membersPaginated.members.forEach(e=>{
        this.memberAffiliateList.push(
          {
            memberImage: e.profilePicture,
            fullName: e.name+' '+e.firstLastname,
            email:e.email,
            id: e._id,
            selected: false,
          },
        )

      });
      this.membersVinculatedTotal = this.membersPaginated.totalDocs
    }
    
  },
  computed:{
    ...mapState("members",["membersPaginated"]),
  }
};
</script>

<style scoped>
.card_tittle {
  font-size: 17px;
  font-weight: bold;
}

.member_counter{
  background-color: #9bf4d5;
  padding: 0px 5px;
  font-weight: bold;
  border-radius: 50px;
}

.profile{
  color: white; font-size: 12px;
}
.member_uncounter{
  background-color: #f49bb1;
  padding: 0px 5px;
  font-weight: bold;
  border-radius: 50px;
}
.list_member{
  margin-top: 5px;
  overflow: auto;
  height: 150px;
}

.vinculated_member{
  margin-top: 5px;
  overflow: auto;
  height: 200px;
}

.list_member::-webkit-scrollbar,.vinculated_member::-webkit-scrollbar{
  width: 10px;
}
.list_member::-webkit-scrollbar-track,.vinculated_member::-webkit-scrollbar-track {
  background-color:#f0f0f0 ;
}
 
.list_member::-webkit-scrollbar-thumb,.vinculated_member::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.member_name {
  font-size: 18px;
}
</style>