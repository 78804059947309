import Vue from "vue";
import Endpoints from "@/share/Endpoints"

export const fetchPublications = async () => {
  try {
      const data = await Vue.axios({
        url: Endpoints.allPublications,
        method: 'GET'
      })
    return { status: true, data:  data.data.publications }
  } catch (error) {
    return { message: error, status: false }
  }
}
export const fetchMemberPublications = async () => {
  try {
      const data = await Vue.axios({
        url: Endpoints.memberPublications,
        method: 'GET'
      })
    return { status: true, data:  data.data.publications }
  } catch (error) {
    return { message: error, status: false }
  }
}

export const fetchPublicationById = async ({commit}, publicationId) => {
  try {
    const {data} = await Vue.axios({
      url: Endpoints.publications.replace(':publicationId', publicationId),
      method: 'GET'
    })

    return { status: true, data: data.publication}
  } catch (error) {
    return { status: false, message: error }
  }
}

export const createPublication = async ({commit}, publication) => {
  try {
    const data = await Vue.axios({
      url: Endpoints.createPublications,
      method: 'POST',
      data: {
        "publication":{
          ...publication
        }
      }
    })
    return { status: true, data: data }
  } catch (error) {
    return { status: false, message: error }
  }
}

export const updatePublication = async ({commit}, publication) => {
  try {
    const data = await Vue.axios({
      url: Endpoints.updatePublication.replace(':publicationId', publication._id),
      method: 'PUT',
      data: {
        "publication":{
          ...publication
        }
      }
    })
    console.log(data);
    return { status: true, data: data}
  } catch (error) {
    return { status: false, message: error }
  }
}

export const deletePublication = async ({commit}, publication) => {
  try {
    const response = await Vue.axios({
      method: 'DELETE',
      url: Endpoints.deletePublication.replace(':publicationId', publication._id)
    })

    return { status: true, data: response}
  } catch (error) {
    return { status: false, error: error }
  }
}